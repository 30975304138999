// import components below
import PageHeaderUI from 'components/UI/PageHeaderUI';

// main component
const AdmissionHeader = (props) => {
  const { active } = props;

  return (
    <div className='admissionHeader'>
      <PageHeaderUI>
        <h4>Electronic Health / Medical Records</h4>
        <p>Electronic Health / Medical Records / {active}</p>
      </PageHeaderUI>
    </div>
  );
};

export default AdmissionHeader;
