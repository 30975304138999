/* eslint-disable no-unused-vars */
//  import core & vendor packages below
import _ from 'lodash';
import axios from 'axios';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// import components below
import Filter from './Filter';
import ViewForm from './Form';
import FormTabTable from './Table';
import ConditionalRender from 'components/ConditionalRender';

// import assets below
import './index.scss';
import { getMedicalForms } from 'reducers/thunks/medicalRecordThunks';
import { selectConfinementRecords, selectMedicalForms } from 'reducers/medicalRecordReducer';

// Main Component
const FormTab = () => {
  const dispatch = useDispatch();

  const [state, setState] = useState([]);
  const [search, setSearch] = useState('');
  const [showScreen, setShowScreen] = useState(0);
  const [viewForm, setViewForm] = useState({
    type: '',
    data: {},
    patientID: '',
  });

  const isView = showScreen === 1;
  const isDefault = showScreen === 0;

  const records = useSelector(selectMedicalForms);

  // get records by record type
  const getRecordByType = (recordType, typeLabel) => {
    const recordByType = _.filter(
      records,
      (record) => record?.type === recordType && record?.patientId !== null,
    );

    const recordDates = _.chain(recordByType)
      .uniqBy((record) => getDate(record?.updatedAt))
      .map((record) => getDate(record?.updatedAt))
      .value();

    return _.map(recordDates, (date, index) => ({
      id: index + 1,
      type: typeLabel,
      date,
      tableData: _.filter(recordByType, (record) => getDate(record?.updatedAt) === date),
    }));
  };

  // date entries
  const getDate = (date) => {
    return moment(date).format('YYYY-DD-MM');
  };

  const baseRecords = [
    {
      id: 1,
      tabLabel: 'CERTIFICATE OF CONFINEMENT',
      data: records && getRecordByType('certificate_of_confinement', 'CERTIFICATE OF CONFINEMENT'),
    },
    {
      id: 2,
      tabLabel: 'MEDICAL CERTIFICATE',
      data: records && getRecordByType('medical_cert', 'MEDICAL CERTIFICATE'),
    },
    {
      id: 3,
      tabLabel: 'MEDICO - LEGAL CERTIFICATE',
      data: getRecordByType('medico_legal_cert', 'MEDICO - LEGAL CERTIFICATE'),
    },
    {
      id: 4,
      tabLabel: 'CERTIFICATE OF DISMEMBERED BODY PART',
      data: getRecordByType(
        'cert_of_dismembered_body_part',
        'CERTIFICATE OF DISMEMBERED BODY PART',
      ),
    },
    {
      id: 5,
      tabLabel: 'SUBPOENA AD TESTIFICANDUM',
      data: getRecordByType('subpoena_ad_testificandum', 'SUBPOENA AD TESTIFICANDUM'),
    },
    {
      id: 6,
      tabLabel: 'SUBPOENA DUCES TECUM',
      data: getRecordByType('subpoena_duces_tecum', 'SUBPOENA DUCES TECUM'),
    },
    {
      id: 7,
      tabLabel: 'MEDICAL RECORDS',
      data: getRecordByType('medical_record', 'MEDICAL RECORDS'),
    },
    {
      id: 8,
      tabLabel: 'OUTPATIENT RECORDS',
      data: getRecordByType('outpatient_record', 'OUTPATIENT RECORDS'),
    },
    {
      id: 9,
      tabLabel: 'EMERGENCY TREATMENT RECORD',
      data: getRecordByType('emergency_treatment', 'EMERGENCY TREATMENT RECORD'),
    },
    {
      id: 10,
      tabLabel: 'ADMISSION AND DISCHARGE RECORD',
      data: getRecordByType('admission_and_discharge', 'ADMISSION AND DISCHARGE RECORD'),
    },
    {
      id: 11,
      tabLabel: 'DISCHARGE SUMMARY',
      data: getRecordByType('discharge_summary', 'DISCHARGE SUMMARY'),
    },
    {
      id: 12,
      tabLabel: 'HISTORY AND PHYSICAL EXAMINATION',
      data: getRecordByType('history_and_physical_examination', 'HISTORY AND PHYSICAL EXAMINATION'),
    },
    {
      id: 13,
      tabLabel: 'LIVE BIRTH FORM',
      data: getRecordByType('live_birth_form', 'LIVE BIRTH FORM'),
    },
    {
      id: 14,
      tabLabel: 'DEATH FORM',
      data: getRecordByType('cert_of_death', 'DEATH FORM'),
    },
    {
      id: 15,
      tabLabel: 'FETAL DEATH FORM',
      data: getRecordByType('cert_of_fetal_death', 'FETAL DEATH FORM'),
    },
  ];

  const handleSearch = () => {
    if (search === '') {
      setState(baseRecords);
    } else {
      const newFilter = baseRecords.filter((value) => {
        return value.tabLabel.toLowerCase().includes(search.toLowerCase());
      });

      setState(newFilter);
    }
  };

  useEffect(() => {
    dispatch(getMedicalForms());
  }, []);

  useEffect(() => {
    setState(baseRecords);
  }, [records]);

  return (
    <div className='form-tab'>
      <ConditionalRender
        condition={isDefault}
        renderIf={
          <>
            <Filter setSearch={setSearch} handleSearch={handleSearch} />

            <FormTabTable
              state={state}
              setState={setState}
              setViewForm={setViewForm}
              setShowScreen={setShowScreen}
            />
          </>
        }
      />

      <ConditionalRender
        condition={isView}
        renderIf={
          <ViewForm setViewForm={setViewForm} viewForm={viewForm} setShowScreen={setShowScreen} />
        }
      />
    </div>
  );
};

export default FormTab;
