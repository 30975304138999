// import style below
import './Footer.scss';

// main component
const Footer = () => {
  return (
    <div className='footer'>
      <span>Patient Care Management</span>
      <span>2022 {process.env.REACT_APP_NAME}. All Rights Reserved.</span>
    </div>
  );
};

export default Footer;
