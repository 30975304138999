/* eslint-disable */
// Import core & vendor packages below
import { Tabs, Tab } from 'react-bootstrap';

// Import components below
import TabsUI from 'components/UI/TabsUI';

// Tab components
import FormTab from './Forms';
import Archive from './Archive';
import OPDRecords from './OPDRecords';
import ActivePatient from './ActivePatient';
import DischargeSummary from './DischargeSummary';

// Main component
const MedicalRecordsTabs = (props) => {
  const { onChange, active } = props;

  let tabs = [
    {
      id: 1,
      title: 'Active Patient',
      component: ActivePatient,
    },
    {
      id: 2,
      title: 'Discharge Summary',
      component: DischargeSummary,
    },
    {
      id: 3,
      title: 'OPD Records',
      component: OPDRecords,
    },
    {
      id: 4,
      title: 'Archive',
      component: Archive,
    },
    {
      id: 5,
      title: 'Forms',
      component: FormTab,
    },
  ];

  return (
    <div className='MedicalRecordsTabs'>
      <TabsUI>
        <Tabs activeKey={active} onSelect={(eventKey) => onChange(eventKey)}>
          {tabs.map(({ title, component: Component }, idx) => (
            <Tab key={idx} eventKey={title} title={title}>
              <Component />
            </Tab>
          ))}
        </Tabs>
      </TabsUI>
    </div>
  );
};

export default MedicalRecordsTabs;
