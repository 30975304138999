// Roles and Departments

const Status = {
    NEW: 'new',
    ADMITTED: 'admitted',
    OUTPATIENT: 'outpatient',
    EMERGENCY: 'emergency',
    FOR_DISCHARGE: 'for_discharge',
    DISCHARGED: 'discharged',
    DECLINED: 'declined',
    AWAITING_BED: 'awaiting bed',
    ABSCONDED: 'absconded',
    DIED: 'died',
    FOR_BILLING: 'for_billing',
    FOR_CLEARANCE: 'for_clearance',
};

export default Status;

/**
 * As per si bryan
ang for Billing yan yung running bill, ibig sabihin pwede pa madagdagan
ang for Clearance, yan yung nagfile na ng discharge procedure si Nurse, ang mangyayare lang dyan, hindi na pwede magdagdag nh procedures, item, etc na billable
 */