/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */ 
/* eslint-disable comma-spacing */
/* eslint-disable no-unneeded-ternary  */
/* eslint-disable comma-dangle */
//  import core & vendor packages below
import { Col, Container, Row } from 'react-bootstrap';
import './PrintMedicalRecords.scss';

// main component
export default function PrintMedicalRecords({ data, viewFormType }) {
  const {
    name_of_hospital,
    cert_no,
    address,
    lname,
    given_name,
    mname,
    age,
    sex,
    hrn,
    occupation,
    civil_status,
    citizenship,
    religion,
    patient_address,
    arrival_date,
    arrival_time,
    reffered_by,
    social_worker,
    case_type,
    NOI,
    TOI,
    DOI,
    POI,
    allergies,
    last_tetanus,
    current_rx,
    is_good_condition,
    is_coma,
    is_poor_condition,
    is_critical_condition,
    is_hemorrhage,
    is_shock,
    time,
    temperature,
    cardiac_rate,
    BP,
    refferend_by,
    resp_rate,
    physical_findings,
    diagnosis,
    treatment,
    disposition,
    disposition_time,
    condition_on_discharge,
    nurse_on_duty,
    attending_physician,
  } = data;
  return (
    <Container>
    <Row className='mb-4'>
      <Col className='first-row'>
        <div className='input-group'>
          <p>{name_of_hospital}</p>
          <label>Name of Healthcare Facility</label>
        </div>
        <div className='input-group'>
          <p>{address}</p>
          <label>Address</label>
        </div>
      </Col>
    </Row>

    <Row className='mb-4'>
      <Col className='second-row'>
        <div className='input-group'>
          <p>{cert_no}</p>
          <label>Certificate No.</label>
        </div>
        {/* <div className='input-group'>
          <label>Date</label>
          <p>{date}</p>
        </div> */}
      </Col>
    </Row>

    <Row className='mb-4'>
      <Col className='third-row'>
        <h2>{viewFormType}</h2>
      </Col>
    </Row>

    <Row className='mb-2'>
      <Col className='bordered-container information'>
        <div className='midright-cont'>
          <div className='input-group'>
            <label>Last Name</label>
            <p>{lname}</p>
          </div>

          <div className='input-group'>
            <label>Given Name</label>
            <p>{given_name}</p>
          </div>

          <div className='input-group'>
            <label>Middle Name</label>
            <p>{mname}</p>
          </div>
        </div>

        <div className='midright-cont'>
          <div className='row-group'>
            <div className='input-group'>
              <label>Age</label>
              <p>{age}</p>

              <div className='input-group small'>
                <label>Civil Status</label>
                <p>{civil_status}</p>
              </div>

              <div className='input-group big'>
                <label>Address</label>
                <p>{patient_address}</p>
              </div>
            </div>
          </div>
        </div>

        <div className='mid-cont'>
          <div className='row-group'>
            <div className='input-group'>
              <label>Sex</label>
              <p>{sex}</p>
            </div>

            <div className='input-group small'>
              <label>Citizenship</label>
              <p>{citizenship}</p>
            </div>
          </div>
        </div>

        <div className='midleft-cont'>
          <div className='row-group'>
            <div className='input-group small'>
              <label>HRN</label>
              <p>{hrn}</p>
            </div>

            <div className='input-group religion'>
              <label>Religion</label>
              <p>{religion}</p>
            </div>
          </div>
        </div>

        <div className='left-cont'>
          <div className='row-group'>
            <div className='input-group small'>
              <label>Occupation</label>
              <p>{occupation}</p>
            </div>
          </div>
        </div>
      </Col>
    </Row>

    <h4 className='mt-1 mb-4 emergency-header'>EMERGENCY RECORD ROOM</h4>

    <Row>
      <div className='date-group mb-1'>
        <label>Date</label>
        <p>{arrival_date}</p>
      </div>

      <Col className='bordered-container emergency-record-room'>
        <div className='info_one'>
          <h5>ARRIVAL TIME</h5>
          <div className='input-group'>
            <p>{arrival_time}</p>
          </div>
        </div>

        <div className='info_two'>
          <h5>REFFERED BY</h5>
          <div className='checkbox'>
            <div className='selections'>
              <p>{reffered_by}</p>
            </div>
          </div>
        </div>

        <div className='info_three'>
          <h5>SOCIAL WORKER</h5>
          <div className='checkbox'>
            <div className='selections'>
              <p>{social_worker}</p>
            </div>
          </div>
        </div>

        <div className='info_four'>
          <h5>CASE TYPE</h5>
          <div className='checkbox'>
            <div className='selections'>
              <p>{case_type}</p>
            </div>
          </div>
        </div>
      </Col>
    </Row>

    <Row>
      <Col className='brief-history mt-5 mb-3'>
        <h5 className='letterSizes'>
          BRIEF HISTORY: ( if accident, state where, when and how, injured. If illness
          describe, note case prior to admission )
        </h5>

        <div className='physical_diagnosis'>
          <label style={{ marginRight: '30px' }}>NOI:</label>
          <div>
            <div className='input-group mb-3 big'>
              <input
                type='textbox'
                value={NOI}
                className='NOI'
                style={{ width: '730px', borderRadius: '10px !important', opacity: 0.8 }}
              />
            </div>
          </div>
        </div>

        <div className='physical_diagnosis'>
          <label style={{ marginRight: '30px' }}>TOI:</label>
          <div>
            <div className='input-group mb-3 big'>
              <input
                type='textbox'
                value={TOI}
                className='TOI'
                style={{ width: '730px', borderRadius: '10px !important', opacity: 0.8 }}
              />
            </div>
          </div>
        </div>

        <div className='physical_diagnosis'>
          <label style={{ marginRight: '30px' }}>DOI:</label>
          <div>
            <div className='input-group mb-3 big'>
              <input
                type='textbox'
                value={DOI}
                className='DOI'
                style={{ width: '730px', borderRadius: '10px !important', opacity: 0.8 }}
              />
            </div>
          </div>
        </div>

        <div className='physical_diagnosis'>
          <label style={{ marginRight: '30px' }}>POI:</label>
          <div>
            <div className='input-group mb-3 big'>
              <input
                type='textbox'
                value={POI}
                className='POI'
                style={{ width: '730px', borderRadius: '10px !important', opacity: 0.8 }}
              />
            </div>
          </div>
        </div>
      </Col>
      <div>
        <div className='checkbox mb-3'>
          <label style={{ marginRight: '10px' }}>Allergies</label>
          <input
            type='textbox'
            value={allergies}
            className='allergies'
            style={{ width: '165px', borderRadius: '10px !important', opacity: 0.8 }}
          />
          <label style={{ marginRight: '10px', marginLeft: '10px' }}>Last Tetanus</label>
          <input
            type='textbox'
            value={last_tetanus}
            className='last_tetanus'
            style={{ width: '165px', borderRadius: '10px !important', opacity: 0.8 }}
          />
          <label style={{ marginRight: '10px', marginLeft: '10px' }}>Current Rx</label>
          <input
            type='textbox'
            value={current_rx}
            className='current_rx'
            style={{ width: '165px', borderRadius: '10px !important', opacity: 0.8 }}
          />
        </div>
      </div>
    </Row>

    <Row className='mb-2'>
      <Col className='bordered-container information'>
        <div className='condition_on_arrival'>
          <h5 className='titleSizes'>CONDITION ON ARRIVAL</h5>

          <div>
            <div className='condition_on_arrival'>
              <div className='checkbox'>
                <input
                  type='checkbox'
                  style={{ marginRight: '5px' }}
                  checked={is_good_condition === 'GOOD'}
                  className='is_good_condition'
                />
                <label style={{ marginRight: '15px' }}>GOOD</label>
                <input
                  type='checkbox'
                  style={{ marginRight: '5px' }}
                  checked={is_hemorrhage === 'HEMORRHAGE'}
                  className='is_hemorrhage'
                />
                <label>HEMORRHAGE</label>
              </div>

              <div className='checkbox'>
                <input
                  type='checkbox'
                  style={{ marginRight: '5px' }}
                  checked={is_shock === 'SHOCK'}
                  className='is_shock'
                />
                <label style={{ marginRight: '15px' }}>SHOCK</label>
                <input
                  type='checkbox'
                  style={{ marginRight: '5px' }}
                  checked={is_coma === 'COMA'}
                  className='is_coma'
                />
                <label>COMA</label>
              </div>

              <div>
                <input
                  type='checkbox'
                  style={{ marginRight: '5px' }}
                  checked={is_poor_condition === 'POOR'}
                  className='is_poor_condition'
                />
                <label>POOR</label>
              </div>

              <div>
                <input
                  type='checkbox'
                  style={{ marginRight: '5px' }}
                  checked={is_critical_condition === 'CRITICAL'}
                  className='is_critical_condition'
                />
                <label>CRITICAL</label>
              </div>
            </div>
          </div>
        </div>

        <div className='line-2'>
          <h5 className='titleSizes'>TIME</h5>
          <div className=''>
            <div>
              <p>{time}</p>
            </div>
          </div>
        </div>

        <div className='line-3'>
          <h5 className='titleSizes'>TEMPERATURE</h5>
          <div className='input-group'>
            <div>
              <p>{temperature}</p>
            </div>
          </div>
        </div>

        <div className='line-4'>
          <h5 className='titleSizes'>CARDIAC RATE</h5>
          <div className='input-group'>
            <div>
              <p>{cardiac_rate}</p>
            </div>
          </div>
        </div>

        <div className='line-5'>
          <h5 className='titleSizes'>BP</h5>
          <div className='input-group'>
            <div>
              <p>{BP}</p>
            </div>
          </div>
        </div>

        <div className='line-6'>
          <h5 className='titleSizes'>RESP RATE</h5>
          <div className='input-group'>
            <div>
              <p>{resp_rate}</p>
            </div>
          </div>
        </div>
      </Col>
    </Row>

    <Row>
      <Col className='brief-history mt-3 mb-1 ml-3'>
        <div className='physical_diagnosis'>
          <label style={{ marginRight: '10px' }}>PHYSICAL FINDINGS</label>
          <div>
            <div className='input-group mb-3 big'>
              <input
                type='textbox'
                value={physical_findings}
                className='physical_findings'
                style={{ width: '650px', borderRadius: '10px !important', opacity: 0.8 }}
              />
            </div>
          </div>
        </div>
        <div className='physical_diagnosis'>
          <label style={{ marginRight: '10px' }}>DIAGNOSIS</label>
          <div>
            <div className='input-group mb-3 big'>
              <input
                type='textbox'
                value={diagnosis}
                className='diagnosis'
                style={{ width: '700px', borderRadius: '10px !important', opacity: 0.8 }}
              />
            </div>
          </div>
        </div>
        <div className='physical_diagnosis'>
          <label style={{ marginRight: '10px' }}>TREATMENT</label>
          <div>
            <div className='input-group mb-3 big'>
              <input
                type='textbox'
                value={treatment}
                className='treatment'
                style={{ width: '700px', borderRadius: '10px !important', opacity: 0.8 }}
              />
            </div>
          </div>
        </div>
      </Col>
    </Row>
    <Row>
      <Col className='brief-history mb-3'>
        <div className='condition_on_arrival'>
          <label style={{ marginRight: '3px' }}>DISPOSITION</label>
          <div>
            <div className='disposition'>
              <div className='checkbox'>
                <input
                  type='checkbox'
                  style={{ marginRight: '5px' }}
                  checked={disposition === 'TREATED & DISCHARGED'}
                  className='disposition'
                />
                <label style={{ marginRight: '15px' }}>TREATED & DISCHARGED</label>
                <input
                  type='checkbox'
                  style={{ marginRight: '5px' }}
                  checked={disposition === 'ADMITTED'}
                  className='disposition'
                />
                <label style={{ marginRight: '15px' }}>ADMITTED </label>
                <input
                  type='checkbox'
                  style={{ marginRight: '5px' }}
                  checked={disposition === 'TRANSFERED TO'}
                  className='disposition'
                />
                <label style={{ marginRight: '15px' }}>TRANSFERED TO </label>
                <label style={{ marginRight: '15px' }}>TIME </label>
                <input
                  type='time'
                  style={{ marginRight: '5px' }}
                  value={disposition_time}
                  className='disposition_time'
                />
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>

    <Row>
      <Col className='brief-history mb-3'>
        <div className='condition_on_arrival'>
          <label style={{ marginRight: '3px' }}>CONDITION UPON DISCHARGE</label>
          <div>
            <div className='disposition'>
              <div className='checkbox'>
                <input
                  type='checkbox'
                  style={{ marginRight: '5px' }}
                  checked={condition_on_discharge === 'GOOD'}
                  className='condition_on_discharge'
                />
                <label style={{ marginRight: '15px' }}>GOOD</label>
                <input
                  type='checkbox'
                  style={{ marginRight: '5px' }}
                  checked={condition_on_discharge === 'SATISFACTORY'}
                  className='condition_on_discharge'
                />
                <label style={{ marginRight: '15px' }}>SATISFACTORY </label>
                <input
                  type='checkbox'
                  style={{ marginRight: '5px' }}
                  checked={condition_on_discharge === 'SERIOUS'}
                  className='condition_on_discharge'
                />
                <label style={{ marginRight: '15px' }}>SERIOUS </label>
                <input
                  type='checkbox'
                  style={{ marginRight: '5px' }}
                  checked={condition_on_discharge === 'CRITICAL'}
                  className='condition_on_discharge'
                />
                <label style={{ marginRight: '15px' }}>CRITICAL </label>
                <input
                  type='checkbox'
                  style={{ marginRight: '5px' }}
                  checked={condition_on_discharge === 'EXPIRED'}
                  className='condition_on_discharge'
                />
                <label style={{ marginRight: '15px' }}>EXPIRED </label>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>

    <Row>
      <Col className='signatures mb-4 mt-5'>
        <div className='d-flex justify-content-between'>
          <div className='float-left'>
            <input
              value={nurse_on_duty}
              className='nurse_on_duty'
              style={{ width: '200px', marginLeft: '30px' }}
            />
            <div>
              <label label style={{ marginLeft: '30px' }}>
                SIGNATURE OF NURSE ON DUTY
              </label>
            </div>
          </div>
          <div className='float-right'>
            <input
              value={attending_physician}
              className='attending_physician'
              style={{ width: '200px', marginRight: '5px' }}
            />
            <div>
              <label style={{ marginRight: '30px' }}>SIGNATURE OF ATTENDING PYSICIAN</label>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  </Container>
  );
}