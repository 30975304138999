/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
//  import core & vendor packages below
import axios from 'axios';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Row, Form, Col, Button, Image, Table } from 'react-bootstrap';

// import components below
import TableUI from 'components/UI/TableUI';
import AppointmentInformationModal from './Modal';

// import assets below
import { ReactComponent as UserSVG } from 'assets/svg/user.svg';
import { ReactComponent as ArrowSVG } from 'assets/svg/chevron-right.svg';

// main component
const Profile = ({ showProfile, setShowProfile, setShowScreen }) => {
  const [data, setData] = useState([]);
  const [appointment, setAppointment] = useState({

  });

  useEffect(() => {
    axios
      .get(`/patient/${showProfile.profileId}`)
      .then((response) => {
        setData(response.data.data);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });
  }, []);

  const [show, setShow] = useState(false);

  return (
    <div className='view-profile'>
      <Row className='mb-4'>
        <Col className='header-buttons'>
          <Button
            className='back-button'
            onClick={() => {
              setShowScreen(0);
            }}
          >
            Back to Patient List
          </Button>
        </Col>
      </Row>

      <Row className='mb-4'>
        <Col className='profile-info'>
          <Form.Group className='mb-3 avatar-container'>
            <UserSVG />
          </Form.Group>

          <Form.Group className='profile-name'>
            <h4>
              <b>{data?.last_name}</b> ,
              {data?.prefix ? data?.prefix : ''}{' '}
              {data?.first_name}
            </h4>
            <h6>
              Patient ID: <span>{showProfile?.profileId}</span>
            </h6>
            <div className='info'>
              <p>
                {data?.age} / {data?.sex}
              </p>{' '}
              &nbsp;
              <p>{moment(data?.date_of_birth).format('MMMM-DD-YYYY')}</p>
            </div>
          </Form.Group>
        </Col>
      </Row>

      <Row className='mb-5 patient-banner'>
        <Col lg='3' className='right'>
          <div className='p-3'>
            <h6>Allergies:</h6>
            <div className='allergies d-flex flex-column'>
              {data?.patient_allergies?.length > 0 ? (
                data?.patient_allergies?.map((item, key) => {
                  return (
                    <span key={key} className='fw-bold'>
                      {' '}
                      {item?.allergy}
                    </span>
                  );
                })
              ) : (
                <span className='fw-bold'>N/A</span>
              )}
            </div>
          </div>
        </Col>
        <Col lg='6'>
          <div className='d-flex flex-column align-items-between h-100 p-3'>
            <Row>
              <Col>
                <div className='mt-2'>
                  <span>Location: </span> <span className='fw-bold'>
                    {data?.assigned_room
                      ? data?.assigned_room?.room?.room
                      : '-'}
                  </span>
                </div>
              </Col>
              <Col>
                <div className='mt-2'>
                  <span>Admission Date: </span>  <span>
                    {data?.admission_date
                      ? moment(data?.admission_date).format('MMMM-DD-YYYY')
                      : '-'}
                  </span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className='mt-2'>
                  <span>Visit Number: </span>
                  <span className='fw-bold'>
                    {data?.active_visit_number
                      ? data?.active_visit_number
                      : '-'}
                  </span>
                </div>
              </Col>
              <Col>
                <div className='mt-2'>
                  <span>Length of Stay: </span> <span className='fw-bold'>0 Days</span>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col lg='3' className='left'>
          <div className='p-3'>
            <h6>Doctors:</h6>
            <div className='d-flex flex-column'>
              {data?.assigned_doctors?.length > 0 ? (
                data?.assigned_doctors?.map((item) => {
                  return (
                    <span key='doctor' className='fw-bold'>
                      Dr. {item?.user?.full_name} {item?.user?.first_name} {item?.user?.middle_name}
                    </span>
                  );
                })
              ) : (
                <span className='fw-bold'>N/A</span>
              )}
            </div>
          </div>
        </Col>
      </Row>

      <Row className='mb-4'>
        <Col className='discharge-table'>
          <h5>Medications</h5>

          <TableUI>
            <Table responsive>
              <thead>
                <tr>
                  <th>Appointment Date & Time</th>
                  <th>Doctor</th>
                  <th>Department</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data?.appointments?.map((data) => {
                    return (
                      <tr key={data.id}>
                        <td>{moment(data.appointment_date).format('MM/DD/YYYY | hh:mm A')}</td>
                        <td>Dr. {data.name_of_doctor}</td>
                        <td>{data.department}</td>
                        <td>
                          <span
                            className='badge'
                            style={{ background: '#02961C', cursor: 'pointer' }}
                          >
                            {data?.appointment_status}
                          </span>
                        </td>
                        <td className='buttons-cont'>
                          <Button
                            onClick={() => {
                              setShow(true);
                              setAppointment({
                                appointment_date: data?.appointment_date,
                                mobile_number: data?.mobile_number,
                                email: data?.email,
                                doctor: data?.name_of_doctor,
                                department: data?.department,
                                patientId: data?.patient?.id,
                                patientFullName: data?.patient?.full_name,
                              });
                            }}
                            className='view-info'
                          >
                            View Info
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </TableUI>
        </Col>
      </Row>

      <AppointmentInformationModal show={show} setShow={setShow} showProfile={showProfile} appointment={appointment} />
    </div>
  );
};

Profile.defaultProps = {
  setShowScreen: () => { },
};

Profile.propTypes = {
  setShowScreen: PropTypes.func,
};

export default Profile;
