/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { OverlayTrigger, Popover, Button, Row, Col } from 'react-bootstrap';

import { removeItem } from 'utils/storage';
import { getItem, getParsedItem } from 'utils/storage';
import { logout, setIsAuthenticated } from 'reducers/authReducer';

import './style.scss';
import AvatarLogo from 'assets/images/avatar.jpg';
import { ReactComponent as UserSVG } from 'assets/svg/user_circle.svg';
import { ReactComponent as UserCircle } from 'assets/svg/user_circle.svg';
import { ReactComponent as BracketSVG } from 'assets/svg/arrow-up-from-bracket.svg';
import { useEffect } from 'react';

const UserAccount = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [items, setItems] = useState([]);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem('user'));
    if (items) {
      setItems(items);
    }
  }, []);

  const logOut = () => {
    removeItem('accessToken');

    setTimeout(() => {
      dispatch(logout());
      window.location.href = `/`;
    }, 100);
  };

  const userInfo = getParsedItem('userinfo');

  return (
    <div className='user-account'>
      <OverlayTrigger
        trigger='focus'
        placement='left'
        overlay={
          <Popover>
            <Popover.Body className='d-flex justify-content-between p-0'>
              <div className='user-account-cont'>
                <Row>
                  <Col>
                    <div className='account-details'>
                      <img alt='account picture' src={AvatarLogo} />
                      <span>
                        {userInfo?.full_name || `${userInfo?.first_name} ${userInfo?.last_name}`}
                      </span>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className='profile-details'>
                      <UserCircle className='profile-default-logo' />
                      <Button onClick={() => navigate('/dashboard/acl/edit-profile')}>
                        Profile
                      </Button>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className='profile-details'>
                      <BracketSVG className='profile-default-logo' />
                      <Button onClick={logOut}>Logout</Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Popover.Body>
          </Popover>
        }
      >
        <Button variant='secondary' className='popoverButton bg-transparent p-0 border-0'>
          <UserSVG />
        </Button>
      </OverlayTrigger>
    </div>
  );
};

export default UserAccount;
