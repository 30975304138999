// import assets below
import './Content.scss';

// main component
const Content = (props) => {
  const { children } = props;

  return <div className='content position-relative'>{children}</div>;
};

export default Content;
