/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
//  import core & vendor packages below
import React, { useEffect, useState } from 'react';
import { Col, Container, Form, Row, Table } from 'react-bootstrap';

// import assets below
import './index.scss';

// main component
const headerTitleStyle = {
  fontWeight: 'bold',
  color: 'black',
  fontSize: '16px',
};
const headerSubtitleStyle = {
  fontWeight: 'bold',
  color: 'black',
  fontSize: '14px',
  marginTop: '5px',
};
const PrintContent = React.forwardRef(({ data, viewFormType }, ref) => {
  const [printData, setPrintData] = useState({
    age: '',
    date: '',
    address: '',
    cert_no: '',
    purpose: '',
    full_name: '',
    name_of_hospital: '',
    health_record_no: '',
    confined_end_date: '',
    confined_start_date: '',
    attending_physician: '',
    name_of_person_requesting: '',
  });

  const { REACT_APP_LOGO, REACT_APP_HOSPITAL_NAME, REACT_APP_HOSPITAL_STREET_ADDRESS } =
    process.env;

  useEffect(() => {
    if (data) {
      setPrintData(data);
    }
  }, [data]);

  return (
    <div className='print_body' ref={ref}>
      <header>
        <div className='header_logo'>
          <img src={REACT_APP_LOGO} alt='print_logo' />
        </div>
        {/* Inline styling is used due to css class names .header_title and  .header_subtitle is not working at times */}
        <div style={headerTitleStyle}>{REACT_APP_HOSPITAL_NAME}</div>
        <div style={headerSubtitleStyle}>{REACT_APP_HOSPITAL_STREET_ADDRESS}</div>
      </header>
      <Table bordered>
        <thead>
          <tr>
            <th>{viewFormType}</th>
          </tr>
        </thead>
        <tbody>
          <Container>
            <Row className='mb-4'>
              <Col className='first-row'>
                <div className='input-group'>
                  <p>{printData?.name_of_hospital}</p>
                  <label>Name of Hospital</label>
                </div>
                <div className='input-group'>
                  <p>{printData?.address}</p>
                  <label>Address</label>
                </div>
              </Col>
            </Row>

            <Row className='mb-4'>
              <Col className='second-row'>
                <div className='input-group'>
                  <label>Certificate No.</label>
                  <p>{printData?.cert_no}</p>
                </div>

                <div className='input-group'>
                  <label>Health Record No.</label>
                  <p>{printData?.health_record_no}</p>
                </div>

                <div className='input-group'>
                  <label>Date</label>
                  <p>{printData?.date}</p>
                </div>
              </Col>
            </Row>

            <Row className='mb-4'>
              <Col className='third-row'>
                <h2>{viewFormType}</h2>
              </Col>
            </Row>

            <Row className='mb-2'>
              <Col>
                <p className='mb-3'>
                  This is to certify that {printData?.full_name}, {printData?.age} years old of {printData?.address} has been
                  confined in this hospital from {printData?.confined_start_date} to {printData?.confined_end_date}
                </p>

                <p className='mb-5'>
                  This certification is being issued at the request of {printData?.name_of_person_requesting}{' '}
                  for {printData?.purpose}
                </p>

                <div className='signature mb-4'>
                  <div className='attending-physician'>
                    <p>{printData?.attending_physician}</p>
                    <label>HIMD Head / Supervisor</label>
                  </div>
                </div>

                <p className='seal pb-4'>( NOT VALID WITHOUT SEAL )</p>
              </Col>
            </Row>
          </Container>
        </tbody>
      </Table>
    </div>
  );
});

PrintContent.displayName = 'PrintContent';

export default PrintContent;
