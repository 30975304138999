/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
// import core & vendor packages below
import { useSelector } from 'react-redux';
import { Row, Col, Table } from 'react-bootstrap';

// import components below
import TableUI from 'components/UI/TableUI';
import { selectPatientVisitDetails } from 'reducers/medicalRecordReducer';

// import assets below
import './OrdersTab.scss';

// main component
const OrdersTab = ({ showProfile, setShowProfile }) => {
  const patientProfile = useSelector(selectPatientVisitDetails);

  // console.log('patient profile;', patientProfile);

  return (
    <div className='ordersTab'>
      <div className='patientProfile__header'>
        <h4>Medications</h4>
        <Row>
          <Col>
            <TableUI>
              <Table className='medicationsTable'>
                <thead>
                  <tr>
                    <th>Medication Description</th>
                    <th>Doze</th>
                    <th>Route of Administration</th>
                    <th>Times of Administration</th>
                    <th>Unit of measure</th>
                    <th>Unit price</th>
                    <th>QTY</th>
                    <th>Total Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {patientProfile?.medications &&
                    patientProfile?.medications.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.medication}</td>
                          <td>{item.doze}</td>
                          <td>{item.route_of_administration}</td>
                          <td>{item.times_of_administration}</td>
                          <td>{item.uom}</td>
                          <td>{item.unit_price}</td>
                          <td>{item.quantity}</td>
                          <td>{item.total_amount}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </TableUI>
          </Col>
        </Row>
      </div>
      <div className='patientProfile__header'>
        <h4>Supplies</h4>
        <Row>
          <Col>
            <TableUI>
              <Table className='medicationsTable'>
                <thead>
                  <tr>
                    <th>Supply Description</th>
                    <th>Quantity</th>
                    <th>Priority</th>
                    <th>Notes</th>
                  </tr>
                </thead>
                <tbody>
                  {patientProfile?.supplies &&
                    patientProfile?.supplies.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.supply}</td>
                          <td>{item.quantity}</td>
                          <td>{item.priority}</td>
                          <td>{item.notes}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </TableUI>
          </Col>
        </Row>
      </div>
      <div className='patientProfile__header'>
        <h4>Procedures</h4>
        <Row>
          <Col>
            <TableUI>
              <Table className='medicationsTable'>
                <thead>
                  <tr>
                    <th>Surgery Code</th>
                    <th>Description</th>
                    <th>Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  {patientProfile?.request_procedures &&
                    patientProfile?.request_procedures.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.ctp_code}</td>
                          <td>{item.procedure}</td>
                          <td>{item.remarks}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </TableUI>
          </Col>
        </Row>
      </div>
      <div className='patientProfile__header'>
        <h4>Tests</h4>
        <Row>
          <Col>
            <TableUI>
              <Table className='medicationsTable'>
                <thead>
                  <tr>
                    <th>Internal/External?</th>
                    <th>Lab Type</th>
                    <th>Ordering Physician</th>
                    <th>Status</th>
                    <th>Notes</th>
                  </tr>
                </thead>
                <tbody>
                  {patientProfile?.laboratory_requests &&
                    patientProfile?.laboratory_requests.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.lab_test}</td>
                          <td>{item.lab_type}</td>
                          <td>{item?.user?.full_name}</td>
                          <td>{item.status}</td>
                          <td>{item.notes}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </TableUI>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default OrdersTab;
