/* eslint-disable no-unused-vars */
// import core & vendor packages below
import React from 'react';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { InputGroup, Form, Button } from 'react-bootstrap';

// import components
import { removeItem } from 'utils/storage';
import UserAccount from 'components/UserAccount';
import { logout, setIsAuthenticated } from 'reducers/authReducer';

// import assets below
import './Topbar.scss';
import Links from 'constant/links';
import { ReactComponent as MenuSVG } from 'assets/svg/menu.svg';
import { ReactComponent as UserSVG } from 'assets/svg/user_circle.svg';
import { ReactComponent as MagnifyingGlassSVG } from 'assets/svg/magnifying_glass.svg';

// main component
const DashboardTopbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logOut = () => {
    removeItem('accessToken');
    setTimeout(() => {
      dispatch(logout());
      window.location.href = `/`;
    }, 100);
  };

  const selectPath = (path) => {
    navigate(path);
  };

  const [menuIsOpen, setMenuIsOpen] = React.useState(false);

  return (
    <div className='dashboardTopbar'>
      <div className='dashboardTopbar__header'>
        <span className='dashboardTopbar__brand'>{process.env.REACT_APP_SIDE_BAR_NAME}</span>
      </div>
      <div className='dashboardTopbar__wrapper'>
        <Button>
          <MenuSVG />
        </Button>
        <InputGroup>
          <div style={{ width: '300px' }}>
            <Select
              menuIsOpen={menuIsOpen}
              menuPlacement='auto'
              menuPosition='fixed'
              components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
              onInputChange={(input) => {
                setMenuIsOpen(input);
              }}
              onChange={(search) => selectPath(search.path)}
              isClearable={true}
              options={Links}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.path}
              placeholder={
                <div>
                  <MagnifyingGlassSVG width='20' className={'d-inline ml-6'} />
                  <span> Search</span>
                </div>
              }
            />
          </div>
        </InputGroup>
        <UserAccount />
      </div>
    </div>
  );
};

export default DashboardTopbar;
