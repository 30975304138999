/* eslint-disable multiline-comment-style */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import moment from 'moment';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { Row, Col, Button } from 'react-bootstrap';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// import components below
import FormUI from 'components/UI/FormUI';
import PatientProfileTabs from './components/Tabs';
import { showPatient } from 'reducers/patientReducer';
import { PrintContent } from './components/PrintContent';
import { showVisitDetails, selectPatientVisitDetails } from 'reducers/medicalRecordReducer';

// import assets below
import './style.scss';
import { ReactComponent as UserSVG } from 'assets/svg/user.svg';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark2.svg';

// main component
const FormPatientProfile = ({ showScreen, showProfile, setShowProfile, setShowScreen }) => {
  const dispatch = useDispatch();
  const printRef = useRef();

  const [canEdit, setCanEdit] = useState(false);

  const patientProfile = useSelector(selectPatientVisitDetails);

  useEffect(() => {
    dispatch(
      showVisitDetails({
        visitNumber: showProfile?.visitNumber,
      }),
    );

    const GetData = async () => {
      try {
        const response = await dispatch(showPatient(showProfile?.patientId));

        if (response) {
          setCanEdit(true);
        }
      } catch (error) {
        toast.error('Something went wrong while retriving patient data. Please refresh the page');
      }
    };

    GetData();
  }, [showProfile, showScreen]);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  return (
    <>
      <div style={{ display: 'none' }}>
        <PrintContent ref={printRef} patientProfile={patientProfile} showProfile={showProfile} />
      </div>
      <div className='formPatientProfile'>
        <div className='d-flex justify-content-end'>
          <CircleXMark
            style={{ width: '2em', cursor: 'pointer' }}
            onClick={() => {
              setShowScreen(0);
            }}
          />
        </div>
        <FormUI>
          <div className='formPatientProfile__header'>
            <h4>Patient Profile</h4>
            <div>
              <Button className='print-btn me-3' onClick={handlePrint}>
                Print
              </Button>

              {canEdit && (
                <Link
                  className='btn btn-save'
                  to={{ pathname: '/dashboard/outpatient-department/edit-patient-profile' }}
                >
                  EDIT PATIENT PROFILE
                </Link>
              )}
            </div>
          </div>
          <br></br>

          <div className='avatar__container'>
            <div className='svg__container'>
              <UserSVG />
            </div>
            <h2 className='mt-4 text-uppercase'>
              <span className='patient__lastName fw-bold'>{patientProfile?.last_name}</span>,{' '}
              {patientProfile?.first_name}
            </h2>
            <h5 className='patient__number mt-1'>{patientProfile?.id}</h5>
            <div className='patient__info mt-1'>
              <span>
                {patientProfile?.age} / {patientProfile?.sex}
              </span>
              <span>{moment(patientProfile?.date_of_birth).format('MMMM-DD-YYYY')}</span>
            </div>
          </div>

          <div className='formPatientProfile__banner'>
            <Row>
              <Col lg='3'>
                <div className='p-3'>
                  <h6>Allergies:</h6>
                  <div className='d-flex flex-column'>
                    {patientProfile?.patient_allergies?.length > 0 ? (
                      patientProfile?.patient_allergies?.map((item, key) => {
                        return (
                          <span key={key} className='fw-bold'>
                            {' '}
                            {item?.allergy}
                          </span>
                        );
                      })
                    ) : (
                      <span className='fw-bold'>N/A</span>
                    )}
                  </div>
                </div>
              </Col>
              <Col lg='6'>
                <div className='d-flex flex-column align-items-between h-100 p-3'>
                  <Row>
                    <Col>
                      <div className='mt-2'>
                        <span>Location: </span>{' '}
                        <span className='fw-bold'>
                          {patientProfile?.assigned_room
                            ? patientProfile?.assigned_room?.room?.room
                            : '-'}
                        </span>
                      </div>
                    </Col>
                    <Col>
                      <div className='mt-2'>
                        <span>Admission Date: </span>{' '}
                        <span className='fw-bold'>
                          <span>
                            {patientProfile?.admission_date
                              ? moment(patientProfile?.admission_date).format('MM-DD-YYYY')
                              : '-'}
                          </span>
                        </span>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className='mt-2'>
                        <span>Visit Number: </span>
                        <span className='fw-bold'>
                          {patientProfile?.active_visit_number
                            ? patientProfile?.active_visit_number
                            : '-'}
                        </span>
                      </div>
                    </Col>
                    <Col>
                      <div className='mt-2'>
                        <span>Length of Stay: </span>{' '}
                        <span className='fw-bold'>
                          {showProfile?.admissionDays ? showProfile?.admissionDays : '-'}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg='3'>
                <div className='p-3'>
                  <h6>Doctors:</h6>
                  <div className='d-flex flex-column'>
                    {patientProfile?.assigned_doctors?.length > 0 ? (
                      patientProfile?.assigned_doctors?.map((item) => {
                        return (
                          <span key='doctor' className='fw-bold'>
                            Dr. {item?.user?.first_name} {item?.user?.last_name}
                          </span>
                        );
                      })
                    ) : (
                      <span className='fw-bold'>N/A</span>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <PatientProfileTabs showProfile={showProfile} setShowProfile={setShowProfile} />
        </FormUI>
      </div>
    </>
  );
};

FormPatientProfile.defaultProps = {
  showProfile: {},
  setShowProfile: () => {},
  setShowScreen: () => {},
};

FormPatientProfile.propTypes = {
  showProfile: PropTypes.object,
  setShowProfile: PropTypes.func,
  setShowScreen: PropTypes.func,
};

export default FormPatientProfile;
