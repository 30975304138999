/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';

// import components below
import ModalUI from 'components/UI/ModalUI';

// import assets below
import './ConfirmationModal.scss';
import { ReactComponent as CloseSVG } from 'assets/svg/circle-xmark.svg';

// main componet
const ConfirmationModal = ({ setOpenModal, showProfile, setShowProfile }) => {

    const navigate = useNavigate();

    const closeModal = () => {
        setOpenModal(false);
    };

    const admitPatient = async () => {
        const params = {
            id: showProfile?.profileId,
            patient_status: 'admitted',
            admission_date: new Date().toISOString()
                .slice(0, 10),
        };
        axios.put('patient', params)
            .then((response) => {
                toast.success('Successfully admitted patient');
                setTimeout(() => {
                    setShowProfile({ isShowProfile: false, profileId: null });
                }, 2000);
            })
            .catch((err) => {
                console.log('AXIOS ERROR: ', err);
            });
    };

    return (
        <div className='loginModal'>
            <ModalUI>
                <Modal.Dialog>
                    <Modal.Header id='confirmation-modal-header'>
                        <h6>CONFIRMATION</h6>
                        <Button onClick={closeModal}>
                            <CloseSVG />
                        </Button>
                    </Modal.Header>
                    <Modal.Body>

                        <p>
                            Are you sure you want to admit this patient?
                        </p>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button className='loginModal__button' onClick={admitPatient}>
                            ADMIT PATIENT
                        </Button>
                    </Modal.Footer>
                </Modal.Dialog>
            </ModalUI>
        </div>
    );
};

export default ConfirmationModal;
