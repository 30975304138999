//  import core & vendor packages below
import axios from 'axios';
import React, { useState, useEffect } from 'react';

// import components below
import Filter from './Filter';
import Profile from './Profile';
import OPDRecordsTable from './Table';
import FormPatientProfile from '../FormPatientProfile';
import ConditionalRender from 'components/ConditionalRender';

// import assets below
import './index.scss';

// Main Component
const OPDRecords = () => {
  const [state, setState] = useState([]);
  const [showProfile, setShowProfile] = useState({
    isModalShow: false,
    isShowView: false,
    profileId: null,
    appointmentId: null,
    setActive: '',
  });
  const [showScreen, setShowScreen] = useState(0);

  useEffect(() => {
    axios
      .get('/appointment')
      .then((response) => {
        setState(response.data.data.appointments);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });
  }, []);

  const isDefault = showScreen === 0;
  const isViewProfile = showScreen === 1;
  const isEditProfile = showScreen === 2;

  return (
    <div className='opd-records'>
      <ConditionalRender
        condition={isDefault}
        renderIf={
          <>
            <Filter state={state} setState={setState} />

            <OPDRecordsTable
              state={state}
              setState={setState}
              setShowScreen={setShowScreen}
              setShowProfile={setShowProfile}
            />
          </>
        }
      />

      <ConditionalRender
        condition={isViewProfile}
        renderIf={<Profile showProfile={showProfile} setShowScreen={setShowScreen} />}
      />

      <ConditionalRender
        condition={isEditProfile}
        renderIf={
          <FormPatientProfile
            showProfile={showProfile}
            setShowProfile={setShowProfile}
            setShowScreen={setShowScreen}
          />
        }
      />
    </div>
  );
};

export default OPDRecords;
