/* eslint-disable multiline-comment-style */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
//  import core & vendor packages below
import axios from 'axios';
import { toast } from 'react-toastify';
import { useReactToPrint } from 'react-to-print';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Form, Button, FormControl } from 'react-bootstrap';

// import components below
import PatientSelection from './PatientSelection';
import { getPatients } from 'reducers/thunks/patientThunks';
import { selectPatientData } from 'reducers/patientReducer';
import ConditionalRender from 'components/ConditionalRender';
import PrintContent from './PrintComponents/MedicalCertificate';
import { selectActivePatients } from 'reducers/medicalRecordReducer';
import useCreateRecordResponse from './hooks/useCreateRecordResponse';
import { createMedicalCertificate, getMedicalFormsbyID } from 'reducers/thunks/medicalRecordThunks';

// import assets below
import 'react-bootstrap-typeahead/css/Typeahead.css';

// main component
const MedicalCertificate = ({ setShowScreen, setViewForm, viewForm, setIsEdit, isEdit }) => {
  const dispatch = useDispatch();

  const printRef = useRef(null);

  const logs = useSelector(selectActivePatients);
  const patientSelection = useSelector(selectPatientData);

  const [isAdd, setIsAdd] = useState(false);
  const [defaultData, setDefaultData] = useState();
  const [validated, setValidated] = useState(false);
  const [showPatientSelection, setShowPatientSelection] = useState(false);
  const [trigger, setTrigger] = useState({ trigger: false, patientID: false, recordID: false });
  const [formData, setFormData] = useState({
    age: '',
    date: '',
    address: '',
    cert_no: '',
    purpose: '',
    full_name: '',
    type: 'medical_cert',
    confined_end_date: '',
    confined_start_date: '',
    attending_physician: '',
    patientId: Math.random(),
    name_of_person_requesting: '',
  });

  const isAdding = isAdd === true;
  const isEditing = isEdit === true;
  const isNotEditing = isEdit === false;

  const snakeCaseString = (str) => {
    return (
      str &&
      str
        .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
        .map((s) => s.toLowerCase())
        .join('_')
    );
  };

  // For Add
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if (!form.checkValidity() === false) {
      setTrigger({ ...trigger, trigger: true });
      setIsEdit(false);
      dispatch(createMedicalCertificate({ ...formData, method: 'post' }));
      toast.success('Successfully created record.');
      setIsAdd(false);
      return;
    }

    toast.warning('Fill up the  fields');
    setValidated(true);
  };

  // For Edit
  const saveChanges = async () => {
    setTrigger({ ...trigger, trigger: true, recordID: true });

    const res = await dispatch(
      createMedicalCertificate({
        ...formData,
        method: 'put',
        type: 'medical_cert',
      }),
    );

    if (res.payload.status === 'Success') {
      setIsEdit(false);
      setDefaultData(formData);
      return;
    }

    toast.error('Error occured while updating');
    return;
  };

  const fullChange = (key) => (e) => {
    setFormData({
      ...formData,
      ['full_name']: e[0].name,
    });

    setFormData({
      ...formData,
      ['patientId']: e[0].id,
    });
  };

  const handleChange = (key) => (e) => {
    setFormData({
      ...formData,
      [key]: e.target.value,
    });

    return;
  };

  const getType = (type) => {
    switch (type) {
      case 'age':
        return 'number';
      default:
        return 'text';
    }
  };

  const checkKey = (key) => {
    if (key === 'age') {
      return 'number';
    } else if (key === 'confined_start_date' || key === 'confined_end_date') {
      return 'date';
    } else {
      return 'text';
    }
  };

  const handleShowPatientSelection = () => {
    setShowPatientSelection(true);
    return;
  };

  const handleClosePatientSelection = () => {
    setShowPatientSelection(false);
    return;
  };

  const options = patientSelection.map((row) => {
    return { full_name: row.full_name, patientId: row.id };
  });

  const textBox = (key, isReadOnly = false) => {
    return (
      <FormControl
        required
        disabled={!isEdit}
        readOnly={isReadOnly}
        type={`${checkKey(key)}`}
        onChange={key !== 'full_name' && handleChange(key)}
        onClick={key === 'full_name' && handleShowPatientSelection}
        value={typeof formData !== 'undefined' ? formData[key] : ''}
        className={`ms-1 ${isEdit ? 'editable' : 'non-editable'} ${key === 'age' ? 'age' : ''}`}
        style={{
          textAlign: !isEdit && 'center',
          color: !isEdit && '#2196F3',
          borderRadius: 0,
          /* width: `${isEdit ? '' : `${formData[key]?.length}rem`}` */
          boxShadow: 'none',
          fontSize: '14px',
        }}
      />
    );
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onBeforePrint: () => console.log('before printing'),
  });

  useEffect(() => {
    dispatch(getPatients());

    if (Object.keys(viewForm.data).length === 0) {
      setIsAdd(true);
      setIsEdit(true);
      return;
    }

    // for refactor: externalize below axios code in to thunk
    const recordId = viewForm?.data?.id;
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/medical_records/form/${recordId}`)
      .then((response) => {
        setFormData(response.data?.data?.mr_medical_cert);
        setDefaultData(response.data?.data?.mr_medical_cert);
      })
      .catch((error) => console.log('error fetching record info: ', error));
  }, []);

  useEffect(() => {
    if (viewForm?.patientID) {
      setTrigger({ ...trigger, patientID: true });
      const patientid = viewForm?.patientID;
      dispatch(getMedicalFormsbyID(patientid));
    }
  }, []);

  useEffect(() => {
    const {
      isFailed,
      isSuccess,
      patientID,
      successData,
      medicalRecordID,
      isFetchViewSuccess,
      isFetchRecordSuccess,
    } = logs;

    useCreateRecordResponse({
      trigger,
      isFailed,
      dispatch,
      isSuccess,
      patientID,
      setIsEdit,
      setTrigger,
      setShowScreen,
      medicalRecordID,
      isFetchViewSuccess,
      isFetchRecordSuccess,
    });

    /* if (isFetchRecordSuccess && trigger.recordID) {
      const data = medicalRecordID?.mr_medical_cert;
      setFormData(data);
    } */
  }, [logs]);

  return (
    <>
      <div style={{ display: 'none' }}>
        <PrintContent ref={printRef} data={formData} viewFormType={viewForm?.type} />
      </div>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className='mb-4'>
          <Col className='first-row'>
            <Form.Group className='input-group'>
              <FormControl
                placeholder='Enter Here'
                value={formData?.name_of_hospital}
                onChange={handleChange('name_of_hospital')}
                className='text-center'
                disabled={!isEdit}
              />
              <Form.Label>Name of Hospital</Form.Label>
            </Form.Group>

            <Form.Group className='input-group'>
              <FormControl
                placeholder='Enter Here'
                value={formData?.address}
                className='text-center'
                onChange={handleChange('address')}
                disabled={!isEdit}
              />
              <Form.Label>Address</Form.Label>
            </Form.Group>
          </Col>
        </Row>

        <Row className='mb-4'>
          <Col className='second-row'>
            <Form.Group className='input-group'>
              <Form.Label>Certificate No.</Form.Label>
              <FormControl
                placeholder='Enter Here'
                value={formData?.cert_no}
                onChange={handleChange('cert_no')}
                disabled={!isEdit}
              />
            </Form.Group>

            <Form.Group className='input-group'>
              <Form.Label>Health Record No.</Form.Label>
              <FormControl
                placeholder='Enter Here'
                value={formData?.health_record_no}
                onChange={handleChange('health_record_no')}
                disabled={!isEdit}
              />
            </Form.Group>

            <Form.Group className='input-group'>
              <Form.Label>Date</Form.Label>
              <FormControl
                type='date'
                value={formData?.date}
                onChange={handleChange('date')}
                disabled={!isEdit}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className='mb-4'>
          <Col className='third-row'>
            <h2>{viewForm?.type}</h2>
          </Col>
        </Row>

        <Row className='mb-2'>
          <Col>
            <p className='mb-3'>
              This is to certify that
              {textBox('full_name', true)}, {textBox('age', true)} years old of {textBox('address')} has been
              confined in this hospital from {textBox('confined_start_date')} to{' '}
              {textBox('confined_end_date')}
            </p>

            <p className='mb-5'>
              This certification is being issued at the request of{' '}
              <div className={`request-line-bottom ${isEdit ? 'editable' : 'non-editable'}`}>
                {textBox('name_of_person_requesting')}
              </div>
              for{' '}
              <div className={`purpose-line-bottom ${isEdit ? 'editable' : 'non-editable'}`}>
                {textBox('purpose')}
              </div>
            </p>

            <div className='signature mb-4'>
              <Form.Group className='attending-physician'>
                <FormControl
                  value={formData?.attending_physician}
                  className='text-center'
                  onChange={handleChange('attending_physician')}
                  disabled={!isEdit}
                ></FormControl>
                <Form.Label>HIMD Head / Supervisor</Form.Label>
              </Form.Group>
            </div>

            <p className='seal pb-4'>( NOT VALID WITHOUT SEAL )</p>
          </Col>
        </Row>

        <Row className=''>
          <Col className='form-buttons'>
            {/* Buttons to show for Add */}
            <ConditionalRender
              condition={isAdding}
              renderIf={
                <>
                  <Button type='submit' className='save' disabled={trigger.trigger}>
                    CREATE RECORD
                  </Button>
                  <Button
                    onClick={() => {
                      setIsEdit(false);
                      setShowScreen(0);
                    }}
                    className='cancel'
                  >
                    CANCEL
                  </Button>
                </>
              }
            />

            {/* Buttons to show for Edit/View */}
            <ConditionalRender
              condition={!isAdding}
              renderIf={
                <>
                  <ConditionalRender
                    condition={isNotEditing}
                    renderIf={
                      <>
                        <Button className='print' onClick={handlePrint}>
                          PRINT CERTIFICATE
                        </Button>
                        <Button
                          onClick={() => {
                            setIsEdit(true);
                          }}
                          className='edit'
                        >
                          EDIT CERTIFICATE
                        </Button>
                      </>
                    }
                  />

                  <ConditionalRender
                    condition={isEditing}
                    renderIf={
                      <>
                        <Button
                          onClick={() => {
                            saveChanges();
                          }}
                          className='save'
                          disabled={trigger.trigger}
                        >
                          SAVE CHANGES
                        </Button>
                        <Button
                          onClick={() => {
                            setIsEdit(false);
                            setFormData(defaultData);
                          }}
                          className='cancel'
                        >
                          CANCEL
                        </Button>
                      </>
                    }
                  />
                </>
              }
            />
          </Col>
        </Row>
      </Form>
      <PatientSelection
        showPatientSelection={showPatientSelection}
        handleClosePatientSelection={handleClosePatientSelection}
        patientSelection={patientSelection}
        fields={formData}
        setFields={setFormData}
      />
    </>
  );
};

export default MedicalCertificate;
