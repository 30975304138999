/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
// import core & vendor packages below
import PropTypes from 'prop-types';
import { Row, Col, Form, Button, FormControl } from 'react-bootstrap';

// import components below
import FormUI from 'components/UI/FormUI';

// main component
const Filter = ({ setSearch, handleSearch }) => {
  return (
    <div className='Filter'>
      <FormUI>
        <Row className='mb-4'>
          <Col sm={5}>
            <Form.Group>
              <Form.Label>Search</Form.Label>
              <FormControl
                onChange={(e) => setSearch(e.target.value)}
                type='text'
                className='bg-white'
                placeholder='Search Here'
              />
            </Form.Group>
          </Col>
          <Col>
            <div className='buttonWrapper'>
              <Button onClick={handleSearch} variant='search'>
                APPLY
              </Button>
            </div>
          </Col>
        </Row>
      </FormUI>
    </div>
  );
};

Filter.defaultProps = {
  setState: () => {},
};

Filter.propTypes = {
  setState: PropTypes.func,
};

export default Filter;
