/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */ 
//  import core & vendor packages below
import React from 'react';
import { Col, Container, Form, Row, Table } from 'react-bootstrap';

// import assets below
import './index.scss';

// main component
const headerTitleStyle = { 
  fontWeight: 'bold', 
  color: 'black', 
  fontSize: '16px', 
};
const headerSubtitleStyle = { 
  fontWeight: 'bold', 
  color: 'black', 
  fontSize: '14px', 
  marginTop: '5px', 
};
const PrintContent = React.forwardRef(({ data, viewFormType }, ref) => {
  const { REACT_APP_LOGO, REACT_APP_HOSPITAL_NAME, REACT_APP_HOSPITAL_STREET_ADDRESS } = process.env;
  const { 
    name_of_healthcare_facility,
    healthcare_facility_address,
    hrn,
    patient_lname,
    patient_mname,
    patient_fname,
    ward_room_bed_service,
    permanent_address,
    tel_no,
    sex,
    civil_status,
    date_of_birth,
    age,
    birth_place,
    nationality,
    religion,
    occupation,
    employer_type_of_business,
    employer_address,
    employer_contact_no,
    father_name,
    father_address,
    father_contact,
    mother_name,
    mother_address,
    mother_contact,
    spouse_name,
    spouse_address,
    spouse_contact,
    admission_date,
    admission_time,
    discharge_date,
    discharge_time,
    total_no_days,
    admitting_physician,
    admitting_clerk,
    attending_physician,
    type_of_admission,
    reffered_by,
    social_service_classification,
    allergic_to,
    hospitalization_plan,
    health_insurance_name,
    phic,
    data_furnished_by,
    address_of_informat,
    relation_to_patient,
    admission_diagnosis,
    discharge_diagnosis,
    icd_ruv_code,
    other_diagnosis,
    principal_operation_procedure,
    principal_operation_procedure_icd_ruv_code,
    accident_injuries_poisoning,
    disposition,
    result,
    is_died,
    is_died_less_than_48_hours,
    is_died_greater_than_48_hours,
    is_autopsy,
    is_no,
    other_principal_operation_procedure,
    other_principal_operation_procedure_icd_ruv_code,
    type,
  } = data;
  console.log(data);
  return (
    <div className='print_body' ref={ref}> 
      <header>
          <div className='header_logo'>
            <img src={REACT_APP_LOGO} alt='print_logo' />
          </div>
          {/* Inline styling is used due to css class names .header_title and  .header_subtitle is not working at times */}
          <div style={headerTitleStyle}>{REACT_APP_HOSPITAL_NAME}</div>
          <div style={headerSubtitleStyle}>{REACT_APP_HOSPITAL_STREET_ADDRESS}</div>
      </header>
    <Table bordered>
      <thead>
        <tr>
          <th>{viewFormType}</th>
        </tr>
      </thead>
      <tbody>
        <Container>
          <Row className='mb-4'>
            <Col className='first-row'>
              <div className='input-group'>
                <p>{!name_of_healthcare_facility ? 'N/A' : name_of_healthcare_facility}</p>
                <label>Name of Healthcare Facility</label>
              </div>
              <div className='input-group'>
                <p>{!healthcare_facility_address ? 'N/A' : healthcare_facility_address}</p>
                <label>Address</label>
              </div>
            </Col>
          </Row>
          <Row className='mb-4'>
            <Col className='third-row'>
                <h2>ADMISSION AND DISCHARGE RECORD</h2>
            </Col>
          </Row>
          <Row className='mb-3'>
          <Col className='second-row'>
            <div className='input-group'>
                <label>HRN:</label>
                <p>{!hrn ? 'N/A' : hrn}</p>
            </div>
          </Col>
        </Row>
        <Row className='mb-2'>
          <Col className='main-box-container p-0'>
            <div className='box-cont'>
              <h3>PATIENT&apos;S NAME:</h3>

              <div className='row-group d-flex'>
                <div className='input-group'>
                  <label>Last Name</label>
                  <p>{!patient_lname ? 'N/A' : patient_lname}</p>
                </div>

                <div className='input-group'>
                  <label>Given Name</label>
                  <p>{!patient_fname ? 'N/A' : patient_fname}</p>
                </div>

                <div className='input-group'>
                  <label>Middle Name</label>
                  <p>{!patient_mname ? 'N/A' : patient_mname}</p>
                </div>

                <div className='input-group'>
                  <label>WARD / RM / BED / SERVICE</label>
                  <p>{!ward_room_bed_service ? 'N/A' : ward_room_bed_service}</p>
                </div>
              </div>
            </div>

            <div className='box-cont'>
              <Row>
                <Col sm={6}>
                  <div className='input-group'>
                  <label>PERMANENT ADDRESS</label>
                  <p>{!permanent_address ? 'N/A' : permanent_address}</p>
                  </div>
                </Col>
                <Col sm={2}>
                  <div className='input-group'>
                  <label>Tel. No.</label>
                  <p>{!tel_no ? 'N/A' : tel_no}</p>
                  </div>
                </Col>
                <Col sm={1}>
                  <div className='input-group'>
                    <label>Sex</label>
                    <div className='selections'>
                      <div className='d-flex'>
                      <input
                        type='checkbox'
                        checked={sex === 'Male' ? 'true' : 'false'}
                        className='me-1'
                      />
                      <label className='text-uppercase'>Male</label>
                      </div>
                      <div className='d-flex'>
                        <input
                          type='checkbox'
                          className='me-1'
                          checked={sex === 'Female' ? 'true' : 'false'}
                        />
                        <label className='text-uppercase'>Female</label>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={3}>
                  <div className='input-group'>
                    <label>Civil Status</label>
                    <Row>
                      <Col>
                        <div className='selections'>
                          <div className='d-flex'>
                            <input
                              type='checkbox'
                              className='me-1'
                              checked={civil_status === 'S' ? 'true' : 'false'}
                            />
                            <label>S</label>
                          </div>
                          <div className='d-flex'>
                            <input
                              type='checkbox'
                              className='me-1'
                              checked={civil_status === 'W' ? 'true' : 'false'}
                            />
                            <label>W</label>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className='selections'>
                        <div className='d-flex'>
                          <input
                            type='checkbox'
                            className='me-1'
                            checked={civil_status === 'D' ? 'true' : 'false'}
                          />
                          <label>D</label>
                        </div>
                        <div className='d-flex'>
                          <input
                            type='checkbox'
                            className='me-1'
                            checked={civil_status === 'M' ? 'true' : 'false'}
                          />
                          <label>M</label>
                        </div>
                        </div>
                      </Col>
                      <Col>
                        <div className='selections'>
                            <div className='d-flex'>
                                <input
                                  type='checkbox'
                                  className='me-1'
                                  checked={civil_status === 'SEP' ? 'true' : 'false'}
                                />
                                <label>SEP</label>
                            </div>
                            <div className='d-flex'>
                                <input
                                  type='checkbox'
                                  className='me-1'
                                  checked={civil_status === 'N' ? 'true' : 'false'}
                                />
                                <label>N</label>
                            </div>
                        </div>
                      </Col>
                      <Col>
                        <div className='selections'>
                            <div>
                                <input
                                  type='checkbox'
                                  className='me-1'
                                  checked={civil_status === 'C' ? 'true' : 'false'}
                                />
                                <label>C</label>
                            </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>

            <div className='box-cont'>
              <Row>
                <Col sm={2}>
                  <div className='input-group'>
                    <label>Birthdate</label>
                    <p>{date_of_birth}</p>
                  </div>
                </Col>
                <Col sm={1}>
                  <div className='input-group'>
                    <label>AGE</label>
                    <p>{age}</p>        
                  </div>
                </Col>
                <Col sm={3}>
                  <div className='input-group'>
                    <label>Birth Place</label>
                    <p>{birth_place}</p>     
                  </div>
                </Col>
                <Col sm={2}>
                  <div className='input-group'>
                    <label>Nationality</label>
                    <p>{nationality}</p>      
                  </div>
                </Col>
                <Col sm={2}>
                  <div className='input-group'>
                    <label>Religion</label>
                    <p>{religion}</p>  
                  </div>
                </Col>
                <Col sm={2}>
                  <div className='input-group'>
                    <label>Occupation</label>
                    <p>{occupation}</p>  
                  </div>
                </Col>
              </Row>
            </div>

            <div className='box-cont'>
              <Row className='mb-3'>
                <Col>
                  <div className='input-group'>
                    <label>EMPLOYER ( Type of Business )</label>
                    <p>{employer_type_of_business}</p> 
                  </div>
                </Col>
                <Col>
                  <div className='input-group'>
                    <label>Address</label>
                    <p>{employer_address}</p> 
                  </div>
                </Col>
                <Col>
                  <div className='input-group'>
                    <label>Telephone No. / CP No.</label>
                    <p>{employer_contact_no}</p> 
                  </div>
                </Col>
              </Row>

              <Row className='mb-3'>
                <Col>
                  <div className='input-group'>
                    <label>Father&apos;s Name</label>
                    <p>{father_name}</p>
                  </div>
                </Col>
                <Col>
                  <div className='input-group'>
                    <label>Address</label>
                    <p>{father_address}</p>
                  </div>
                </Col>
                <Col>
                  <div className='input-group'>
                    <label>Telephone No. / CP No.</label>
                    <p>{father_contact}</p>
                  </div>
                </Col>
              </Row>

              <Row className='mb-3'>
                <Col>
                  <div className='input-group'>
                    <label>Mother&apos;s ( Maiden ) Name</label>
                    <p>{mother_name}</p>
                  </div>
                </Col>
                <Col>
                  <div className='input-group'>
                    <label>Address</label>
                    <p>{mother_address}</p>
                  </div>
                </Col>
                <Col>
                  <div className='input-group'>
                    <label>Telephone No. / CP No.</label>
                    <p>{mother_contact}</p>
                  </div>
                </Col>
              </Row>

              <Row className=''>
                <Col>
                  <div className='input-group'>
                    <label>Spouse Name</label>
                    <p>{spouse_name}</p>
                  </div>
                </Col>
                <Col>
                  <div className='input-group'>
                    <label>Address</label>
                    <p>{spouse_address}</p>
                  </div>
                </Col>
                <Col>
                  <div className='input-group'>
                    <label>Telephone No. / CP No.</label>
                    <p>{spouse_contact}</p>
                  </div>
                </Col>
              </Row>
            </div>

            <div className='box-cont'>
              <Row>
                <Col>
                  <div className='input-group'>
                    <label>ADMISSION</label>

                    <div className='multiple-input'>
                      <div className='input-group mb-3 inline-label'>
                        <label>Date</label>
                        <p>{admission_date}</p>
                      </div>

                      <div className='input-group inline-label'>
                        <label>Time</label>
                        <p>{admission_time}</p>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className='input-group'>
                    <label>DISCHARGE</label>

                    <div className='multiple-input'>
                      <div className='input-group mb-3 inline-label'>
                        <label>Date</label>
                        <p>{discharge_date}</p>
                      </div>

                      <div className='input-group inline-label'>
                        <label>Time</label>
                        <p>{discharge_time}</p>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={2}>
                  <div className='input-group'>
                    <label>TOTAL NO. OF DAYS</label>
                    <p>{total_no_days}</p>
                  </div>
                </Col>
                <Col>
                  <div className='input-group'>
                    <label>ADMITTING PHYSICIAN</label>
                    <p>{admitting_physician}</p>
                  </div>
                </Col>
              </Row>
            </div>

            <div className='box-cont'>
              <Row>
                <Col>
                  <div className='input-group'>
                    <label>ADMITTING CLERK</label>
                    <p>{admitting_clerk}</p>
                  </div>
                </Col>

                <Col>
                  <div className='input-group'>
                    <label>ADMITTING PHYSICIAN</label>
                    <p>{attending_physician}</p>
                  </div>
                </Col>
              </Row>
            </div>

            <div className='box-cont'>
              <Row>
                <Col>
                  <div className='input-group'>
                    <label>TYPE OF ADMISSION</label>

                    <div className='selections inline-selection d-flex'>
                      <div>
                        <input
                          className='m-1'
                          type='radio'
                          checked={type_of_admission === 'NEW' ? 'true' : 'false'}
                        />
                        <label>NEW</label>
                      </div>
                      <div>
                        <input
                          className='m-1'
                          type='radio'
                          checked={type_of_admission === 'OLD' ? 'true' : 'false'}
                        />
                        <label>OLD</label>
                      </div>
                      <div>
                        <input
                          type='radio'
                          className='m-1'
                          checked={type_of_admission === 'FORMER OPD' ? 'true' : 'false'}
                        />
                        <label>FORMER OPD</label>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col>
                  <div className='input-group'>  
                    <label>REFERRED BY ( Physician / Health Facility )</label>
                    <p>{reffered_by || 'N/A'}</p>
                  </div>
                </Col>
              </Row>
            </div>

            <div className='box-cont'>
              <Row>
                <Col>
                  <div className='input-group inline-label d-flex justify-content-start'>
                    <label>SOCIAL SERVICE CLASSIFICATION</label>
                    <div className='selections inline-selection'>
                      <input
                        className='m-1'
                        type='radio'
                        checked={social_service_classification === 'A' ? 'true' : 'false'}
                      />
                      <label>A</label>
                      <input
                        className='m-1'
                        type='radio'
                        checked={social_service_classification === 'B' ? 'true' : 'false'}
                      />
                      <label>B</label>
                      <input
                        className='m-1'
                        type='radio'
                        checked={social_service_classification === 'C1' ? 'true' : 'false'}
                      />
                      <label>C1</label>
                      <input
                        className='m-1'
                        type='radio'
                        checked={social_service_classification === 'C2' ? 'true' : 'false'}
                      />
                      <label>C2</label>
                      <input
                        className='m-1'
                        type='radio'
                        checked={social_service_classification === 'C3' ? 'true' : 'false'}
                      />
                      <label>C3</label>
                      <input
                        className='m-1'
                        type='radio'
                        checked={social_service_classification === 'D' ? 'true' : 'false'}
                      />
                      <label>D</label>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>

            <div className='box-cont'>
              <Row>
                <Col>
                  <div className='input-group'>
                    <label>ALERT : ALERGIC TO</label>
                    <p>{allergic_to || 'N/A'}</p>
                  </div>
                </Col>
                <Col>
                  <div className='input-group'>
                    <label>
                      HOSPITALIZATION PLAN <br /> ( Company / Industrial Name )
                    </label>
                    <p>{hospitalization_plan || 'N/A'}</p>
                  </div>
                </Col>
                <Col>
                  <div className='input-group'>
                    <label>HEALTH INSURANCE NAME</label>
                    <p>{health_insurance_name || 'N/A'}</p>
                  </div>
                </Col>
                <Col>
                  <div className='input-group'>
                    <label>PHIC</label>

                    <Row>
                      <Col>
                        <div className='selections'>
                          <input
                            className='m-1'
                            type='radio'
                            checked={phic === 'SSS' ? 'true' : 'false'}
                          />
                          <label>SSS</label>
                          <input
                            className='m-1'
                            type='radio'
                            checked={phic === 'SSS Dependent' ? 'true' : 'false'}
                          />
                          <label>SSS Dependent</label>
                        </div>
                      </Col>
                      <Col>
                        <div className='selections'>
                          <input
                            className='m-1'
                            type='radio'
                            checked={phic === 'GSIS' ? 'true' : 'false'}
                          />
                          <label>GSIS</label>
                          <input
                            className='m-1'
                            type='radio'
                            checked={phic === 'GSIS Dependent' ? 'true' : 'false'}
                          />
                          <label>GSIS Dependent</label>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>

            <div className='box-cont'>
              <Row>
                <Col>
                  <div className='input-group'>
                    <label>DATA FURNISHED BY</label>
                    <p>{data_furnished_by || 'N/A'}</p>
                  </div>
                </Col>
                <Col>
                  <div className='input-group'>
                    <label>ADDRESS OF INFORMANT</label>
                    <p>{address_of_informat || 'N/A'}</p>
                  </div>
                </Col>
                <Col>
                  <div className='input-group'>
                    <label>RELATION TO PATIENT</label>
                    <p>{relation_to_patient || 'N/A'}</p>
                  </div>
                </Col>
              </Row>
            </div>

            <div className='box-cont'>
              <Row>
                <Col>
                  <div className='input-group'>
                    <label>ADMISSION DIAGNOSIS</label>
                    <p>{admission_diagnosis || 'N/A'}</p>
                  </div>
                </Col>
              </Row>
            </div>

            <div className='box-cont'>
              <Row>
                <Col>
                  <div className='input-group'>
                    <label>DISCHARGE DIAGNOSIS</label>

                    <div className='multiple-input'>
                      <div className='input-group mb-3'>
                        <label>PRINCIPAL DIAGNOSIS</label>
                        <p>{discharge_diagnosis || 'N/A'}</p>
                      </div>

                      <div className='input-group'>
                        <label>OTHER DIAGNOSIS</label>
                        <p>{other_diagnosis || 'N/A'}</p>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className='input-group'>
                    <label>ICD / RUV CODE:</label>
                    <p>{icd_ruv_code || 'N/A'}</p>
                  </div>
                </Col>
              </Row>
            </div>

            <div className='box-cont'>
              <Row>
                <Col>
                  <div className='input-group'>
                    <label>PRINCIPAL OPERATION / PROCEDURE</label>
                    <p>{principal_operation_procedure || 'N/A'}</p>
                  </div>
                </Col>
                <Col>
                  <div className='input-group'>
                    <label>ICD / RUV CODE:</label>
                    <p>{principal_operation_procedure_icd_ruv_code || 'N/A'}</p>
                  </div>
                </Col>
              </Row>
            </div>

            <div className='box-cont'>
              <Row>
                <Col>
                  <div className='input-group'>
                    <label>OTHER OPERATION (S) PROCEDURE (S)</label>
                    <p>{other_principal_operation_procedure || 'N/A'}</p>
                  </div>
                </Col>
                <Col>
                  <div className='input-group'>
                    <label>ICD / RUV CODE:</label>
                    <p>{other_principal_operation_procedure_icd_ruv_code || 'N/A'}</p>
                  </div>
                </Col>
              </Row>
            </div>

            <div className='box-cont'>
              <Row>
                <Col>
                  <div className='input-group'>
                    <label>ACCIDENT / INJURIES / POISONING</label>
                    <p>{accident_injuries_poisoning || 'N/A'}</p>
                  </div>
                </Col>
              </Row>
            </div>

            <div className='box-cont'>
              <Row>
                <Col>
                  <div className='input-group'>
                    <label>DISPOSITION</label>

                    <Row>
                      <Col>
                        <div className='selections d-flex flex-column'>
                        <div>
                          <input
                            type='checkbox'
                            className='m-1'
                            checked={disposition === 'Discharge' ? 'true' : 'false'}
                          />
                          <label>Discharge</label>
                        </div>
                        <div>
                          <input
                            type='checkbox'
                            className='m-1'
                            checked={disposition === 'Transferred' ? 'true' : 'false'}
                          />
                          <label>Transferred</label>
                        </div>
                        </div>
                      </Col>
                      <Col>
                        <div className='selections d-flex flex-column'>
                          <div>
                            <input
                              type='checkbox'
                              className='m-1'
                              checked={disposition === 'HAMA' ? 'true' : 'false'}
                            />
                            <label>HAMA</label>
                          </div>
                          <div>
                            <input
                              type='checkbox'
                              checked={disposition === 'Absconded' ? 'true' : 'false'}
                              className='m-1'
                            />
                            <label>Absconded</label>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col>
                  <div className='input-group'>
                    <label>RESULTS</label>

                    <Row>
                      <Col>
                        <div className='selections'>
                        <div>
                            <input
                              type='radio'
                              checked={result === 'Recovered' ? 'true' : 'false'}
                              className='m-1'
                            />
                            <label>Recovered</label>
                        </div>
                        <div>
                            <input
                              type='radio'
                              className='m-1'
                              checked={result === 'Improved' ? 'true' : 'false'}
                            />
                            <label>Improved</label>
                        </div>
                        <div>
                            <input
                              type='radio'
                              className='m-1'
                              checked={result === 'Unimproved' ? 'true' : 'false'}
                            />
                            <label>Unimproved</label>
                        </div>
                        </div>
                      </Col>
                      <Col>
                        <div className='selections'>
                            <div>
                                <input
                                  type='radio'
                                  className='m-1'
                                  checked={is_died === 'DIED' ? 'true' : 'false'}
                                />
                                <label>is_died</label>
                            </div>
                            <div>
                                <input
                                  type='radio'
                                  className='m-1'
                                  checked={is_died_less_than_48_hours === '-48 hours' ? 'true' : 'false'}
                                />
                                <label>-48 hours</label>
                            </div>
                            <div>
                                <input
                                  type='radio'
                                  className='m-1'
                                  checked={is_died_greater_than_48_hours === '+48 hours' ? 'true' : 'false'}
                                />
                                <label>+48 hours</label>
                            </div>
                        </div>
                      </Col>
                      <Col>
                        <div className='selections'>
                            <div>
                                <input
                                  type='radio'
                                  className='m-1'
                                  checked={is_autopsy === 'Autopsy' ? 'true' : 'false'}
                                />
                                <label>Autopsy</label>
                            </div>
                            <div>
                                <input
                                  type='radio'
                                  className='m-1'
                                  checked={is_no === 'No' ? 'true' : 'false'}
                                />
                                <label>No</label>
                            </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        </Container>
      </tbody>
      </Table>
    </div>
  );
}); 

PrintContent.displayName = 'PrintContent';

export default PrintContent; 