/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

// import components below
import Filter from './Filter';
import Profile from './Profile';
import Status from 'constant/status';
import ActivePatientTable from './Table';
import FormPatientProfile from '../FormPatientProfile';
import ConditionalRender from 'components/ConditionalRender';
import { checkStringPermission } from 'helpers/filteredPermissions';

// import assets below
import './index.scss';

// Main Component
const ActivePatient = () => {
  const [state, setState] = useState([]);
  const [showScreen, setShowScreen] = useState(0);
  const [showProfile, setShowProfile] = useState({
    setActive: '',
    profileId: null,
    isShowView: false,
    visitNumber: null,
    isModalShow: false,
  });

  useEffect(() => {
    axios
      .get('/patient', {
        params: {
          patient_status: [
            Status.NEW,
            Status.ADMITTED,
            Status.DECLINED,
            Status.EMERGENCY,
            Status.OUTPATIENT,
            Status.AWAITING_BED,
            Status.FOR_DISCHARGE,
          ],
        },
      })
      .then((response) => {
        setState(response.data.data);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });
  }, []);

  runOnLocationChange(setShowProfile, setShowScreen);

  const isDefault = showScreen === 0;
  const isViewProfile = showScreen === 1;
  const isEditProfile = showScreen === 2;

  return (
    <div className='active-patient'>
      <ConditionalRender
        condition={isDefault}
        renderIf={
          <>
            <Filter state={state} setState={setState} />

            <ActivePatientTable
              state={state}
              setState={setState}
              showProfile={showProfile}
              setShowScreen={setShowScreen}
              setShowProfile={setShowProfile}
            />
          </>
        }
      />

      <ConditionalRender
        condition={isViewProfile}
        renderIf={
          <Profile
            showProfile={showProfile}
            setShowScreen={setShowScreen}
            setShowProfile={setShowProfile}
          />
        }
      />

      <ConditionalRender
        condition={isEditProfile}
        renderIf={
          <FormPatientProfile
            showScreen={showScreen}
            showProfile={showProfile}
            setShowScreen={setShowScreen}
            setShowProfile={setShowProfile}
          />
        }
      />
    </div>
  );
};

const runOnLocationChange = (setShowProfile, setShowScreen) => {
  const location = useLocation();

  useEffect(() => {
    if (location?.state && location?.state?.isModalShow) {
      setShowScreen(2);
      setShowProfile((prev) => {
        return {
          ...prev,
          profileId: location?.state?.profileId,
          visitNumber: location?.state?.visit_number,
        };
      });
    }
  }, [location?.state]);
};

export default ActivePatient;
