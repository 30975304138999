/* eslint-disable camelcase */
/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import axios from 'axios';
import moment from 'moment';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Form, Col, Button } from 'react-bootstrap';

// import components below
import { selectAuth } from 'reducers/authReducer';
import ModalAddDiagnosis from './components/AddDiagnosis';
import ConditionalRender from 'components/ConditionalRender';
import { checkStringPermission } from 'helpers/filteredPermissions';
import { showPatientHistory, selectPatientHistory } from 'reducers/medicalRecordReducer';

// import assets below
import Status from 'constant/status';
import { ReactComponent as UserSVG } from 'assets/svg/user.svg';
import { ReactComponent as ArrowSVG } from 'assets/svg/chevron-right.svg';

// main components
const Profile = ({ showProfile, setShowProfile, setShowScreen, setState }) => {
  const dispatch = useDispatch();

  const userData = useSelector(selectAuth);
  const data = useSelector(selectPatientHistory);
  const { permissions } = userData;

  const [showDiagnosis, setShowDiagnosis] = useState({
    profileId: null,
    isShowDiagnosis: false,
  });

  const params = {
    id: showProfile?.profileId,
  };

  const reAdmitPatient = async (id, option, userDiagnosis = null) => {
    axios
      .post(`/patient/reactivate`, {
        patientId: id,
        patient_status: option,
        patient_origin: option,
        diagnosis: userDiagnosis,
      })
      .then((response) => {
        axios
          .get('/patient', {
            params: {
              patient_status: [Status.DISCHARGED],
            },
          })
          .then((response) => {
            setState(response.data.data);
          }, [])
          .catch((err) => {
            console.log('AXIOS ERROR: ', err);
          });
        toast.success('Successfully transfered to OPD!');
        setShowScreen(0);
      })
      .catch((error) => {
        toast.error('Error processing Request');
      });
  };

  useEffect(() => {
    dispatch(showPatientHistory(params));
  }, []);

  useEffect(() => {
    if (data?.history) {
      setShowProfile((prev) => {
        return {
          ...prev,
          status: data?.history?.patient?.patient_status,
        };
      });
    }
  }, [data]);

  return (
    <div className='view-profile'>
      <Row className='mb-4'>
        <Col md={12}>
          <Button
            className='back-button'
            onClick={() => {
              setShowScreen(0);
            }}
          >
            Back to Patient List
          </Button>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='ms-auto'>
              <Button
                className='me-3'
                variant='addField'
                onClick={() => {
                  reAdmitPatient(showProfile?.profileId, 'emergency');
                }}
              >
                For Emergency
              </Button>
              <Button
                className='me-3'
                variant='light-blue'
                onClick={() => {
                  reAdmitPatient(showProfile?.profileId, 'new');
                  // setShowDiagnosis({ profileId: showProfile?.profileId, isShowDiagnosis: true });
                }}
              >
                For Outpatient
              </Button>
            </div>
          </div>
        </Col>
      </Row>

      <Row className='mb-4'>
        <Col className='profile-info'>
          <Form.Group className='mb-3 avatar-container'>
            <UserSVG />
          </Form.Group>

          <Form.Group className='profile-name'>
            <h4>
              <b>{data?.history?.patient?.last_name}</b> ,
              {data?.history?.patient?.prefix ? data?.history?.patient?.prefix : ''}{' '}
              {data?.history?.patient?.first_name}
            </h4>
            <h6>
              Patient ID: <span>{showProfile?.profileId}</span>
            </h6>
            <div className='info'>
              <p>
                {data?.history?.patient?.age} / {data?.history?.patient?.sex}
              </p>{' '}
              &nbsp;
              <p>{moment(data?.history?.patient?.date_of_birth).format('MMMM-DD-YYYY')}</p>
            </div>
          </Form.Group>
        </Col>
      </Row>

      <Row className='mb-4'>
        <Col
          className='card-container'
          // onClick={() => {
          //   setShowScreen(2);
          //   setShowProfile({
          //     visitNumber: data?.visit_number,
          //   });
          // }}
        >
          <Form.Group>
            <Form.Label>Visit History</Form.Label>

            {data?.history?.history?.length > 0 ? (
              data?.history?.history?.map((item, key) => {
                return (
                  <div
                    onClick={() => {
                      setShowProfile({
                        visitNumber: item.visit_number,
                        patientId: showProfile?.profileId,
                      });
                      setShowScreen(2);
                    }}
                    className='card mb-2'
                    key={key}
                  >
                    <Row>
                      <Col sm={3}>
                        <p>
                          Emergency Date <span>{item?.date}</span>
                        </p>
                      </Col>
                      <Col sm={3}>
                        <p>
                          Length of Stay{' '}
                          <span>
                            {item?.los} {item?.los <= 1 ? 'Day' : 'Days'}
                          </span>
                        </p>
                      </Col>
                      <Col sm={3}>
                        <p>
                          Doctors:
                          {item?.doctors?.length > 0 ? (
                            item?.doctors?.map((item, key) => {
                              return (
                                <span className='small' key={key}>
                                  Dr. {item?.user?.full_name} {item?.user?.first_name}{' '}
                                  {item?.user?.middle_name}
                                </span>
                              );
                            })
                          ) : (
                            <p>-</p>
                          )}
                        </p>
                      </Col>
                      <Col className='arrow'>
                        <ArrowSVG />
                      </Col>
                    </Row>
                  </div>
                );
              })
            ) : (
              <p>No record found</p>
            )}
          </Form.Group>
        </Col>
      </Row>

      <ModalAddDiagnosis
        showProfile={showDiagnosis}
        reAdmitPatient={reAdmitPatient}
        setShowProfile={setShowDiagnosis}
      />
    </div>
  );
};

Profile.defaultProps = {
  setShowScreen: () => {},
};

Profile.propTypes = {
  setShowScreen: PropTypes.func,
};

export default Profile;
