/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// Packages
import axios from 'axios';
import { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  Modal,
  Form,
  FormControl,
  Pagination,
  Table,
  InputGroup,
} from 'react-bootstrap';

// components below
import LabResults from './Table';
import ViewResult from './ViewResult';
import FormUI from 'components/UI/FormUI';
import UploadResult from './UploadResult';
import ConditionalRender from 'components/ConditionalRender';
import { checkStringPermission } from 'helpers/filteredPermissions';

// CSS
import './style.scss';
import { ReactComponent as MagnifyingGlassSVG } from 'assets/svg/magnifying_glass.svg';

// main component
const Results = () => {
  const [state, setState] = useState([]);
  const [requests, setRequests] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [searchedWord, setSearchedWord] = useState('');
  const [searchWordID, setSearchWordID] = useState('');
  const [searchedWordPatient, setSearchedWordPatient] = useState('');

  const [showModal, setShowModal] = useState({
    doctorId: null,
    profileId: null,
    profileData: null,
    labRequestId: null,
    isAddDoctor: false,
    isViewResult: false,
    isStatusShow: false,
  });

  const [userData, setUserData] = useState({
    search_physician: '',
    specialty_subspecialty: '',
  });

  const [result, setResult] = useState({
    remarks: '',
    lab_test: '',
    signed_by: '',
    conducted_by: '',
    date_performed: '',
    licensed_number: '',
  });

  /** filter by patient id */
  const handlePatientIDSearch = (event) => {
    const { value } = event.target;
    setSearchWordID(value);

    const searchedWordPatient = value;

    const newFilter = requests.filter((dd) => {
      const word = dd.patientId.toString();
      return word.includes(searchedWordPatient);
    });

    if (searchedWordPatient.length > 0) {
      setState(newFilter);
    } else {
      setState(requests);
    }
  };

  /** filter by doctor's name */
  const onChangeHandler = (event) => {
    setSearchedWord(event?.target?.value);
    const searchedWord = event?.target?.value;
    const newFilter = requests.filter((value) => {
      const full_name = value?.user?.first_name + ' ' + value?.user?.last_name;
      return full_name?.toLowerCase().includes(searchedWord.toLowerCase());
    });

    if (searchedWord.length > 0) {
      setState(newFilter);
    } else {
      setState(requests);
    }
  };

  /** filter by patient's name */
  const onChangePatientHandler = (event) => {
    setSearchedWordPatient(event.target.value);
    const searchedWordPatient = event.target.value;
    const newFilter = requests.filter((value) => {
      return value.patient.full_name.toLowerCase().includes(searchedWordPatient.toLowerCase());
    });

    if (searchedWordPatient.length > 0) {
      setState(newFilter);
    } else {
      setState(requests);
    }
  };

  const handleChange = (key) => (e) => {
    setUserData({
      ...userData,
      [key]: e.target.value,
    });
  };

  useEffect(() => {
    axios
      .get('/user')
      .then((response) => {
        setDoctors(response.data.data);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });

    axios
      .get('/requisition/laboratory')
      .then((response) => {
        setRequests(response.data.data);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });
  }, []);

  return (
    <>
      <FormUI>
        <div className='add-room-cont mt-4'>
          <Form>
            <h3>Search Patient</h3>
            <br></br>

            <Row className='mb-4'>
              <Col md={3}>
                <Form.Group className='basic-search'>
                  <Form.Label>Patient ID</Form.Label>
                  <Form.Control
                    type='number'
                    placeholder='Enter Here'
                    value={searchWordID}
                    onChange={handlePatientIDSearch}
                  />
                </Form.Group>
              </Col>
              {/* <Col md={3}>
                      <Form.Group className='basic-search'>
                        <Form.Label>Admission Date</Form.Label>
                        <Form.Control
                          type='date'
                          placeholder='Enter Here'
                          onChange={onChangeHandler}
                          value={searchedWord}
                        />
                      </Form.Group>
                    </Col> */}
            </Row>
            <Row>
              <Col md={5}>
                <Form.Group className='basic-search'>
                  <Form.Label>Patient Name</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter Here'
                    onChange={onChangePatientHandler}
                    value={searchedWordPatient}
                  />
                </Form.Group>
              </Col>
              <Col md={5}>
                <Form.Group className='basic-search'>
                  <Form.Label>Doctor Name</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter Here'
                    onChange={onChangeHandler}
                    value={searchedWord}
                  />
                </Form.Group>
              </Col>
              <Col md={2}>
                <Form.Group className='category-buttons'>
                  <Button
                    onClick={() => {
                      // Update Data on Table?
                    }}
                    className='add-button'
                    variant='primary'
                  >
                    Search
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
          <br></br>
          <br></br>

          <h2 className='mb-3'>Patient List</h2>

          <LabResults
            state={state}
            setState={setState}
            setResult={setResult}
            setShowModal={setShowModal}
          />
        </div>
      </FormUI>

      <UploadResult
        result={result}
        setState={setState}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <ViewResult
        result={result}
        setState={setState}
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </>
  );
};

export default Results;
