/* eslint-disable */
// import core & vendor packages below
import axios from 'axios';
import { toast } from 'react-toastify';
import Table from 'components/ReactTable';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// import components below
import { selectPatientData } from 'reducers/patientReducer';

// import assets below
import './Table.scss';

// main component
const LabResults = ({ setShowModal, state, setState, setData }) => {
  const data = useSelector(selectPatientData);

  const tagPatientAsCleared = (id) => {
    axios
      .put(`/clearance/${id}`)
      .then((response) => {
        axios
          .get('/clearance?type=LABORATORY')
          .then((response) => {
            setState(response.data.data);
          }, [])
          .catch((err) => {
            console.log('AXIOS ERROR: ', err);
          });
        toast.success('Successfully tag patient as cleared');
      })
      .catch((error) => {
        console.log(error);
        toast.error('ERROR');
      });
  };

  useEffect(() => {
    axios
      .get('/clearance?type=LABORATORY')
      .then((response) => {
        setState(response.data.data);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });
  }, []);

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Patient Name', accessor: 'name' }, // accessor is the "key" in the data
      { Header: 'Is Cleared?', accessor: 'is_cleared' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      state?.map((dd) => ({
        id: dd?.id,
        name: dd?.patient?.full_name,
        is_cleared: dd?.isCleared ? 'Yes' : 'No',
      })),
    [state],
  );

  const handleTableAction = (eventKey, id) => {
    const row = state.find((dd) => dd.id === id);
    const action = {
      profile: () => {},
      view: () => {
        setData(row?.patient?.laboratory_requests);
        setShowModal((prevState) => {
          return {
            ...prevState,
            showLabRequest: true,
          };
        });
      },
      edit: () => {},
      approve: () => {
        tagPatientAsCleared(row?.id);
      },
    };

    action[eventKey]();
  };

  return (
    <div className='laboratory-clearance-request-table'>
      <Table
        data={tableData}
        columns={tableColumns}
        actions={{ hasView: true, hasApprove: true }}
        onAction={handleTableAction}
        pagination={['BOTTOM']}
      />
    </div>
  );
};

export default LabResults;
