// import core & vendor packages below
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Row } from 'react-bootstrap';

// import components below
import Layout from 'components/Layout';
import MedicalRecordsTabs from './components/Tabs';
import AdmissionHeader from './components/PageHeader';

// import assets below
import './style.scss';

// main component
const MedicalRecords = () => {
  const [activeTab, setActiveTab] = useState('Archive');

  runOnLocationChange(setActiveTab);

  return (
    <Layout pageTitle='MedicalRecordsInventory'>
      <AdmissionHeader active={activeTab} />
      <Container fluid>
        <Row>
          <MedicalRecordsTabs active={activeTab} onChange={setActiveTab} />
        </Row>
      </Container>
    </Layout>
  );
};

const runOnLocationChange = (setActiveTab) => {
  const location = useLocation();

  useEffect(() => {
    if (location?.state && location?.state?.isModalShow) {
      setActiveTab(location?.state?.active);
    }
  }, [location?.state]);
};

export default MedicalRecords;
