// import assets below
import './Banner.scss';
import { ReactComponent as DoctorSVG } from 'assets/svg/dashboard_doctor.svg';

// main component
const DashboardBanner = () => {
  return (
    <div className='dashboardBanner'>
      {/* <img className='dashboardBanner__logo' src={Logo} alt='mandaluyong-his' /> */}
      <div className='divider'></div>
      <div className='w-50 p-3 text-white'>
        <h1>Welcome to {process.env.REACT_APP_FULL_NAME}</h1>
      </div>
      <DoctorSVG />
    </div>
  );
};

export default DashboardBanner;
