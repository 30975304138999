// import core & vendor packages below
import { Fragment, useEffect, useState } from 'react';

// import components below
import ReactHelmet from 'components/ReactHelmet';
import LoadingScreen from 'components/LoadingScreen';
import Topbar from './components/Topbar';
import Sidebar from './components/Sidebar';
import Content from './components/Content';
import Footer from './components/Footer';

// import assets below
import './style.scss';

// main component
const Layout = (props) => {
  const { pageTitle, children } = props;
  const [loading, setLoading] = useState(true);
  const [sidebarToggle, setSidebarToggle] = useState(false);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      setTimeout(() => setLoading(false), 600);
    }

    return () => {
      mounted = false;
      setLoading(true);
    };
  }, []);

  return (
    <Fragment>
      <ReactHelmet title={pageTitle} />
      {loading && <LoadingScreen />}
      {!loading && (
        <div className='layout'>
          {!sidebarToggle && <Sidebar setSidebarToggle={setSidebarToggle} />}
          
          <div className='layout__body'>
            <Topbar toggle={sidebarToggle} onToggle={setSidebarToggle} />
            <Content>{children}</Content>
            <Footer />
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Layout;
