/* eslint-disable */
/* eslint-disable camelcase */
// import core & vendor packages below
import axios from 'axios';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';

// import components below
import Status from 'constant/status';
import Table from 'components/ReactTable';
import Tabs from 'constant/medicalRecordTabs';
import { calculateDayDiff, patientStatus } from 'helpers';
import { TableFiltersPermission } from 'helpers/filteredPermissions';
import { showActivePatients, selectActivePatients } from 'reducers/medicalRecordReducer';

// main component
const DischargeSummaryTable = ({ state, setState, setShowScreen, setShowProfile }) => {
  const [data, setData] = useState([]);
  const [active, setActive] = useState('for_discharge');

  let filterList = [
    {
      key: 'for_discharge',
      name: 'For Discharge Clearance',
    },
    {
      key: 'discharged',
      name: 'Cleared',
    },
  ];

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Location', accessor: 'location' },
      { Header: 'Patient ID', accessor: 'id' }, // accessor is the "key" in the data
      { Header: 'Patient Name', accessor: 'name' },
      { Header: 'Birthdate', accessor: 'birthdate' },
      { Header: 'Age / Sex', accessor: 'ageSex' },
      { Header: 'Status', accessor: 'status' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      state?.map((item) => ({
        location: item?.assigned_room
          ? item?.assigned_room?.room?.floor + ' floor / room # ' + item?.assigned_room?.room?.room
          : '-',
        id: item?.id,
        name: item?.full_name,
        birthdate: moment(item.date_of_birth).format('MMMM-DD-YYYY'),
        ageSex: item?.age + '/' + item.sex,
        status: patientStatus(item?.patient_status),
      })),
    [state],
  );

  const handleTableAction = (eventKey, id) => {
    setShowScreen(1);
    setShowProfile({
      profileId: id,
      // admissionDays: calculateDayDiff((row?.patient?.admission_date)),
    });
  };

  const handleOnClickFilter = (status) => {
    setActive(status);

    axios
      .get('/patient', {
        params: {
          patient_status: status,
        },
      })
      .then((response) => {
        setState(response.data.data);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });
  };

  return (
    <div className='discharge-summary-table'>
      <h2>Discharge Clearance List</h2>

      <Table
        data={tableData}
        columns={tableColumns}
        actions={{ hasEyeView: true }}
        onAction={handleTableAction}
        pagination={['BOTTOM']}
        filter={{
          top: (
            <div className='buttons'>
              {filterList.map((list) => (
                <Button
                  key={list.key}
                  onClick={() => handleOnClickFilter(list.key)}
                  className={list.key === active ? 'active' : ''}
                >
                  {list.name}
                </Button>
              ))}
            </div>
          ),
        }}
      />
    </div>
  );
};

DischargeSummaryTable.defaultProps = {
  state: [],
  setState: () => {},
  setShowScreen: () => {},
};

DischargeSummaryTable.propTypes = {
  state: PropTypes.array,
  setState: PropTypes.func,
  setShowScreen: PropTypes.func,
};

export default DischargeSummaryTable;
