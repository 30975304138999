/* eslint-disable */
/* eslint-disable camelcase */
// import core & vendor packages below
import axios from 'axios';
import moment from 'moment';
import PropTypes from 'prop-types';
import Table from 'components/ReactTable';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// import components below
import Status from 'constant/status';
import { patientStatus } from 'helpers';
import Tabs from 'constant/medicalRecordTabs';
import { showActivePatients, selectActivePatients } from 'reducers/medicalRecordReducer';

// main component
const ActivePatientTable = ({ state, setState, setShowScreen, showProfile, setShowProfile }) => {
  const tableColumns = React.useMemo(
    () => [
      { Header: 'Date/Time Admitted', accessor: 'date' },
      { Header: 'Patient ID', accessor: 'id' }, // accessor is the "key" in the data
      { Header: 'Patient Name', accessor: 'name' },
      { Header: 'Room / Bed', accessor: 'room' },
      { Header: 'Age / Sex', accessor: 'ageSex' },
      { Header: 'Status', accessor: 'status' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () => state && state?.map(item => ({
      date: moment(item.createdAt).format('MMMM DD, YYYY hh:mm A'),
      id: item?.id,
      name: item?.full_name,
      room: item?.assigned_room ? item?.assigned_room?.room?.floor + ' floor / room # ' + item?.assigned_room?.room?.room : '-',
      ageSex: item?.age + ' / ' + item.sex,
      status: patientStatus(item?.patient_status),
    })),
    [state]
  )

  const handleTableAction = (eventKey, id) => {
    setShowScreen(1);
    setShowProfile({
      profileId: id,
    });
  };

  return (
    <div className='active-patient-table'>
      <Table
        data={tableData}
        columns={tableColumns}
        actions={{ hasEyeView: true }}
        onAction={handleTableAction}
        pagination={['BOTTOM']}
      />
    </div>
  );
};

ActivePatientTable.defaultProps = {
  state: [],
  setState: () => {},
  setShowScreen: () => {},
};

ActivePatientTable.propTypes = {
  state: PropTypes.array,
  setState: PropTypes.func,
  setShowScreen: PropTypes.func,
};

export default ActivePatientTable;
