/* eslint-disable no-unused-vars */
// import core & vendor packages below
import { useSelector } from 'react-redux';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { selectIsAuthenticated, selectToken } from 'reducers/authReducer';

// import components below
import ACLPage from 'components/Routes/ACLPage';
import ReportsPage from 'components/Routes/ReportsPage';
import AdmissionPage from 'components/Routes/AdmissionPage';
import EmergencyPage from 'components/Routes/EmergencyPage';
import OutpatientPage from 'components/Routes/OutpatientPage';

import Login from 'pages/Login';
// import Signup from 'pages/Signup';
import Signup from 'pages/NewSignup';
import Doctors from 'pages/Doctors';
import Nursing from 'pages/Nursing';
import Billing from 'pages/Billing';
import Pharmacy from 'pages/Pharmacy';
import Dashboard from 'pages/Dashboard';
import Inventory from 'pages/Inventory';
import AuditLogs from 'pages/AuditLogs';
import Employees from 'pages/Employees';
import Philhealth from 'pages/Philhealth';
import Accounting from 'pages/Accounting';
import Laboratory from 'pages/Laboratory';
import MedicalRecords from 'pages/MedicalRecords';
import ForgotPassword from 'pages/ForgotPassword';
import SocialServices from 'pages/SocialServices';
import ProtectedRoute from 'components/ProtectedRoute';
import RoomBedManagement from 'pages/RoomBedManagement';

// HR
import Loans from 'pages/Loans';
import Leaves from 'pages/Leaves';
import Request from 'pages/Request';
import Payroll from 'pages/Payroll';
import Charges from 'pages/Charges';
import Advances from 'pages/Advances';
import Attendance from 'pages/Attendance';
import FixedAsset from 'pages/FixedAsset';
import OvertimeTracking from 'pages/OvertimeTracking';
import Activities from 'pages/Activities';
import CredentialsManagement from 'pages/CredentialsManagement';

// Auth Service
import setAuthToken from 'services/setAuthToken';

// main component
const AppRouter = () => {
  const accessToken = useSelector(selectToken);
  const isAuthenticated = useSelector(selectIsAuthenticated);

  setAuthToken(accessToken);

  return isAuthenticated ? (
    <BrowserRouter>
      <Routes>
        {/* Public Routes */}
        <Route index element={<Navigate replace to='/dashboard' />} />
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/dashboard/pharmacy' element={<Pharmacy />} />

        {/* Protected Routes */}
        <Route
          path='/dashboard/social-services'
          element={<ProtectedRoute department={'social-services'} component={SocialServices} />}
        />
        <Route
          path='/dashboard/audit-logs'
          element={<ProtectedRoute department={'audit'} component={AuditLogs} />}
        />
        <Route
          path='/dashboard/doctors'
          element={<ProtectedRoute department={'doctor'} component={Doctors} />}
        />
        <Route
          path='/dashboard/nursing'
          element={<ProtectedRoute department={'nurse'} component={Nursing} />}
        />
        <Route
          path='/dashboard/room-bed-management'
          element={<ProtectedRoute department={'roombed'} component={RoomBedManagement} />}
        />
        <Route
          path='/dashboard/laboratory'
          element={<ProtectedRoute department={'laboratory'} component={Laboratory} />}
        />
        <Route
          path='/dashboard/inventory'
          element={<ProtectedRoute department={'inventory'} component={Inventory} />}
        />
        <Route
          path='/dashboard/billing'
          element={<ProtectedRoute department={'billing'} component={Billing} />}
        />
        <Route
          path='/dashboard/philhealth'
          element={<ProtectedRoute department={'philhealth'} component={Philhealth} />}
        />
        <Route
          path='/dashboard/medical-records'
          element={<ProtectedRoute department={'medical records'} component={MedicalRecords} />}
        />
        <Route
          path='/dashboard/accounting'
          element={<ProtectedRoute department={'accounting'} component={Accounting} />}
        />
        <Route
          path='/dashboard/attendance'
          element={<ProtectedRoute department={'attendance'} component={Attendance} />}
        />

        <Route
          path='/dashboard/employees/list'
          element={<ProtectedRoute department={'employees'} component={Employees} />}
        />
        <Route
          path='/dashboard/leaves'
          element={<ProtectedRoute department={'employees'} component={Leaves} />}
        />
        <Route
          path='/dashboard/overtime-tracking'
          element={<ProtectedRoute department={'employees'} component={OvertimeTracking} />}
        />
        <Route
          path='/dashboard/advances'
          element={<ProtectedRoute department={'employees'} component={Advances} />}
        />
        <Route
          path='/dashboard/payroll'
          element={<ProtectedRoute department={'employees'} component={Payroll} />}
        />
        <Route
          path='/dashboard/loans'
          element={<ProtectedRoute department={'employees'} component={Loans} />}
        />
        <Route
          path='/dashboard/charges'
          element={<ProtectedRoute department={'employees'} component={Charges} />}
        />
        <Route
          path='/dashboard/request'
          element={<ProtectedRoute department={'employees'} component={Request} />}
        />
        <Route
          path='/dashboard/activities'
          element={<ProtectedRoute department={'employees'} component={Activities} />}
        />

        <Route
          path='/dashboard/credentials-management'
          element={<ProtectedRoute department={'employees'} component={CredentialsManagement} />}
        />
        <Route
          path='/dashboard/fixed-asset'
          element={<ProtectedRoute department={'employees'} component={FixedAsset} />}
        />

        {ACLPage}
        {ReportsPage}
        {EmergencyPage}
        {AdmissionPage}
        {OutpatientPage}
        {/* Other Page (404 Page) */}
        <Route path='*' element={isAuthenticated ? <Navigate to='/dashboard' /> : null} />
      </Routes>
    </BrowserRouter>
  ) : (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/sign-up' element={<Signup />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path='*' element={!isAuthenticated ? <Navigate to='/' /> : null} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
