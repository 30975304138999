/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable multiline-comment-style */

// import core & vendor packages below
import { Row, Col, Modal, Form } from 'react-bootstrap';

// Import components below
import FormUI from 'components/UI/FormUI';

// import assets below
import './style.scss';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';

// main component
const ModalViewLabRequests = ({ showModal, setShowModal, data }) => {
  return (
    <Modal
      size='lg'
      show={showModal.showLabRequest}
      onHide={() =>
        setShowModal((prevState) => {
          return {
            ...prevState,
            showLabRequest: false,
          };
        })
      }
      aria-labelledby='Add Diagnosis'
      className='add-diagnosis-modal'
    >
      <Modal.Body>
        <div className='add-diagnosis-request'>
          <div className='d-flex header'>
            <p>Lab Requests made by the Patient{showModal?.profileId}</p>

            <CircleXMark
              style={{ width: '2em', cursor: 'pointer' }}
              onClick={() =>
                setShowModal((prevState) => {
                  return { ...prevState, showLabRequest: false };
                })
              }
            />
          </div>
        </div>
        <FormUI>
          <Form className='procedure-form'>
            {data &&
              data.map((item) => {
                return (
                  <>
                    <Row>
                      <Col>
                        <div className='patientProfile__content'>
                          <Row>
                            <Col lg='3'>
                              <div className='wrapper'>
                                <span>Internal/External?</span>
                                <span>:</span>
                              </div>
                            </Col>
                            <Col lg='auto'>
                              <span className='value'>
                                <b>{item.lab_test}</b>
                              </span>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg='3'>
                              <div className='wrapper'>
                                <span>Conducted By: #: </span>
                                <span>:</span>
                              </div>
                            </Col>
                            <Col lg='auto'>
                              <span className='value'>
                                <b>
                                  {item.laboratory_result?.conducted_by
                                    ? item.laboratory_result?.conducted_by
                                    : '-'}
                                </b>
                              </span>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg='3'>
                              <div className='wrapper'>
                                <span>PRC Licensed #: </span>
                                <span>:</span>
                              </div>
                            </Col>
                            <Col lg='auto'>
                              <span className='value'>
                                <b>
                                  {item.laboratory_result?.licensed_number
                                    ? item.laboratory_result?.licensed_number
                                    : '-'}
                                </b>
                              </span>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg='3'>
                              <div className='wrapper'>
                                <span>Date Performed:</span>
                                <span>:</span>
                              </div>
                            </Col>
                            <Col lg='auto'>
                              <span className='value'>
                                <b>
                                  {item.laboratory_result?.date_performed
                                    ? item.laboratory_result?.date_performed
                                    : '-'}
                                </b>
                              </span>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg='3'>
                              <div className='wrapper'>
                                <span>Remarks</span>
                                <span>:</span>
                              </div>
                            </Col>
                            <Col lg='9'>
                              <span className='value'>
                                <b>
                                  {item.laboratory_result?.remarks
                                    ? item.laboratory_result?.remarks
                                    : '-'}
                                </b>
                              </span>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                    <hr></hr>
                  </>
                );
              })}
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

export default ModalViewLabRequests;
