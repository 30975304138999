// import core & vendor packages below
import { toast } from 'react-toastify';
import { Modal, Button } from 'react-bootstrap';

// import components below
import ModalUI from 'components/UI/ModalUI';

// import assets below
import './ClearanceRequestModal.scss';
import { ReactComponent as CloseSVG } from 'assets/svg/circle-xmark.svg';

// main componet
const ClearanceRequestModal = (props) => {
  const { status, onToggle, onConfirm } = props;

  return (
    <div className='clearanceRequestModal'>
      <ModalUI>
        <Modal.Dialog>
          <Modal.Header>
            <h4>Notify Service Unit</h4>
            <Button onClick={() => onToggle(!status)} variant='transparent'>
              <CloseSVG />
            </Button>
          </Modal.Header>
          <Modal.Body>
            <div className='finalizeDischargeModal__content'>
              <div className='finalizeDischargeModal__body'>
                <h6>
                  The service unit/s will be notified that the patient is for discharge clearance.
                  Are you sure you want to proceed?
                </h6>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant='light-blue'
              onClick={() => {
                toast.success('Successfully notified!!');
                onToggle(!status);
                onConfirm((prevState) => !prevState);
              }}
            >
              Notify Unit
            </Button>
            <Button onClick={() => onToggle(!status)} variant='cancel'>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal.Dialog>
      </ModalUI>
    </div>
  );
};

export default ClearanceRequestModal;
