/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */ 
//  import core & vendor packages below
import React from 'react';
import { Col, Container, Form, Row, Table } from 'react-bootstrap';

// import assets below
import './index.scss';

// main component
const headerTitleStyle = { 
  fontWeight: 'bold', 
  color: 'black', 
  fontSize: '16px', 
};
const headerSubtitleStyle = { 
  fontWeight: 'bold', 
  color: 'black', 
  fontSize: '14px', 
  marginTop: '5px', 
};
const PrintContent = React.forwardRef(({ data, viewFormType }, ref) => {
  const { REACT_APP_LOGO, REACT_APP_HOSPITAL_NAME, REACT_APP_HOSPITAL_STREET_ADDRESS } = process.env;
  const { 
    name_of_hospital,
    address,
    lname,
    fname,
    mname,
    hrn,
    age,
    sex,
    civil_status,
    religion,
    occupation,
  } = data;
  console.log(data);
  return (
    <div className='print_body' ref={ref}> 
      <header>
          <div className='header_logo'>
            <img src={REACT_APP_LOGO} alt='print_logo' />
          </div>
          {/* Inline styling is used due to css class names .header_title and  .header_subtitle is not working at times */}
          <div style={headerTitleStyle}>{REACT_APP_HOSPITAL_NAME}</div>
          <div style={headerSubtitleStyle}>{REACT_APP_HOSPITAL_STREET_ADDRESS}</div>
      </header>
    <Table bordered>
      <thead>
        <tr>
          <th>{viewFormType}</th>
        </tr>
      </thead>
      <tbody>
        <Container>
          <Row className='mb-4'>
            <Col className='first-row'>
              <div className='input-group'>
                <label>Name of Hospital</label>
                <p>{!name_of_hospital ? 'N/A' : name_of_hospital}</p>
              </div>
              <div className='input-group'>
                <label>Address</label>
                <p>{!address ? 'N/A' : address}</p>
              </div>
            </Col>
          </Row>

          <Row className='mb-4'>
            <Col>
              <p className='small-header'>NAME ( Last, First, Middle )</p>

              <div className='row-group row-inline'>
                <div className='section'>
                  <label>Last Name :</label>
                  <p>{lname}</p>
                </div>
                <div className='section'>
                  <label>First Name :</label>
                  <p>{fname}</p>
                </div>
                <div className='section'>
                  <label>First Name :</label>
                  <p>{mname}</p>
                </div>
                <div className='section'>
                  <label>HRN :</label>
                  <p>{!hrn ? 'N/A' : hrn}</p>
                </div>
              </div>

              <div className='row-group row-inline'>
                <div className='section'>
                  <label>Age :</label>
                  <p>{age}</p>
                </div>
                <div className='section'>
                  <label>Sex :</label>
                  <p>{sex}</p>
                </div>
                <div className='section'>
                  <label>Civil Status :</label>
                  <p>{!civil_status ? 'N/A' : civil_status}</p>
                </div>
                <div className='section'>
                  <label>Religion :</label>
                  <p>{!religion ? 'N/A' : religion}</p>
                </div>
              </div>

              <div className='row-group row-inline'>
                <div className='section'>
                  <label>Occupation :</label>
                  <p>{!occupation ? 'N/A' : occupation}</p>
                </div>
                <div className='section'>
                  <label>Address :</label>
                  <p>{!address ? 'N/A' : address}</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </tbody>
      </Table>
    </div>
  );
}); 

PrintContent.displayName = 'PrintContent';

export default PrintContent; 