/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
// import core & vendor packages below
import axios from 'axios';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Form, Button, FormControl, InputGroup, Accordion } from 'react-bootstrap';

// import components below
import FormUI from 'components/UI/FormUI';
import { getPatient } from 'reducers/patientReducer';
import { selectPatientData } from 'reducers/patientReducer';

// assets
import './Filter.scss';
import { ReactComponent as AddGroupSVG } from 'assets/svg/add-group.svg';
import { ReactComponent as MagnifyingGlassSVG } from 'assets/svg/magnifying_glass.svg';

// main component
const SearchRoomFilter = ({ setState, handleTabOnChange, setShowProfile }) => {
  const [labTests, setLabTests] = useState([]);
  const [searchData, setSearchData] = useState({
    id: '',
    test_name: '',
    department: '',
    status: '',
  });

  const onChangeHandler = (event) => {
    const searchedWord = event.target.value;

    const newFilter = labTests.filter((value) => {
      return value.name.toLowerCase().includes(searchedWord.toLowerCase());
    });

    if (searchedWord.length > 0) {
      setState(newFilter);
    } else {
      setState(labTests);
    }
  };

  const handleChange = (key) => (e) => {
    setSearchData({
      ...searchData,
      [key]: e.target.value,
    });
  };

  const search = () => {
    axios
      .get('/lab_test', { params: searchData })
      .then((response) => {
        setState(response.data.data);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });
  };

  const resetFilter = async () => {
    setSearchData({
      internal_external: '',
      isActive: '',
    });

    await axios.get('/lab_test').then((response) => {
      setState(response.data.data);
    });
  };

  useEffect(() => {
    axios
      .get('/lab_test')
      .then((response) => {
        setLabTests(response.data.data);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });
  }, []);

  return (
    <div className='searchRoomFilter'>
      <FormUI>
        {/* Basic Search */}
        <Row>
          <Col>
            <Form.Group className='mb-3 mt-3 basic-search'>
              <Form.Label>Basic Search</Form.Label>
              <InputGroup>
                <InputGroup.Text className='bg-grey'>
                  <MagnifyingGlassSVG />
                </InputGroup.Text>
                <FormControl
                  type='search'
                  className='bg-grey'
                  placeholder='Search via Test Name or department...'
                  value={searchData.basic_search}
                  onChange={onChangeHandler}
                />
                {/* <Button variant='search'>SEARCH</Button> */}
              </InputGroup>
            </Form.Group>
          </Col>
          <Col>
            <div className='buttonWrapper'>
              <Button
                className='btn btn-light-blue'
                onClick={() => {
                  setShowProfile((prevState) => {
                    return {
                      ...prevState,
                      showScreen: 2,
                    };
                  });
                }}
              >
                <span>Create Procedure</span>
              </Button>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <Accordion className='mb-3 advanced-search'>
              <Accordion.Item eventKey='0'>
                <Accordion.Header>Advance Search</Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col>
                      <Form.Group className='mb-4' style={{ width: '100%' }}>
                        <Form.Label>Internal/External?</Form.Label>
                        <Form.Select
                          onChange={handleChange('internal_external')}
                          value={searchData.internal_external}
                        >
                          <option hidden value=''>
                            -Select-
                          </option>
                          <option value='External'>External</option>
                          <option value='Internal'>Internal</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-4' style={{ width: '100%' }}>
                        <Form.Label>Status</Form.Label>
                        <Form.Select
                          onChange={handleChange('isActive')}
                          value={searchData.isActive}
                        >
                          <option hidden value=''>
                            -Select-
                          </option>
                          <option value='1'>Active</option>
                          <option value='0'>Inactive</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <div className='buttonWrapper'>
                        <Button variant='search' onClick={() => search()}>
                          SEARCH
                        </Button>

                        <Button variant='clear' onClick={() => resetFilter()}>
                          CLEAR
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </FormUI>
    </div>
  );
};

SearchRoomFilter.defaultProps = {
  setState: () => { },
  handleTabOnChange: () => { },
  setShowProfile: () => { },
};

SearchRoomFilter.propTypes = {
  setState: PropTypes.func,
  handleTabOnChange: PropTypes.func,
  setShowProfile: PropTypes.func,
};

export default SearchRoomFilter;
