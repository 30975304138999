// import components below
import PropTypes from 'prop-types';
import PageHeaderUI from 'components/UI/PageHeaderUI';

// main component
const AdmissionHeader = ({ active }) => {
  return (
    <div className='admissionHeader'>
      <PageHeaderUI>
        <h4>Laboratory Module</h4>
        <p>Dashboard / Laboratory / {active}</p>
      </PageHeaderUI>
    </div>
  );
};

AdmissionHeader.defaultProps = {
  active: '',
};

AdmissionHeader.propTypes = {
  active: PropTypes.string,
};

export default AdmissionHeader;
