/* eslint-disable no-unused-vars */
//  import core & vendor packages below
import axios from 'axios';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Modal, Row, Col, Button } from 'react-bootstrap';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';

// import assets below
import './index.scss';

// main component
const AppointmentInformationModal = ({ show, setShow, showProfile, appointment }) => {
  const handleShow = () => setShow(false);

  const print = () => {
    window.print();
  };

  return (
    <Modal show={show} size='lg' className='appointment-information'>
      <Modal.Body>
        <div className='add-room-request'>
          <div className='d-flex header'>
            <p>Appointment Information {appointment?.id}</p>
            <CircleXMark style={{ width: '2em', cursor: 'pointer' }} onClick={handleShow} />
          </div>
        </div>

        <div className='form'>
          <Row className='mt-4'>
            <Col className='body-content border-bottom'>
              <p>
                Patient ID : <span>{appointment?.patientId}</span>
              </p>
              <p>
                Patient Name : <span>{appointment?.patientFullName}</span>
              </p>
            </Col>
          </Row>

          <Row className='mt-4'>
            <Col className='list-content'>
              <p>APPOINTMENT INFORMATION</p>

              <p>
                Date and Time : <span>{moment(appointment?.appointment_date).format('MM/DD/YYYY | hh:mm A')}</span>
              </p>
              <p>
                Mobile Number : <span>{appointment?.mobile_number}</span>
              </p>
              <p>
                Email Address : <span>{appointment?.email}</span>
              </p>
              <p>
                Doctor : <span>{appointment?.doctor}</span>
              </p>
              <p>
                Department : <span>{appointment?.department}</span>
              </p>
            </Col>
          </Row>

          <Row className='mt-4'>
            <Col className='buttons-cont'>
              <Button className='print' onClick={() => print()}>Print</Button>
              <Button onClick={handleShow} className='close'>
                Close
              </Button>
            </Col>
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  );
};

AppointmentInformationModal.defaultProps = {
  show: false,
  setShow: () => { },
};

AppointmentInformationModal.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
};

export default AppointmentInformationModal;
