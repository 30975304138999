/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
// import core & vendor packages below
import axios from 'axios';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Form, Button, FormControl, InputGroup, Accordion } from 'react-bootstrap';

// import components below
import Status from 'constant/status';
import FormUI from 'components/UI/FormUI';
import Tabs from 'constant/medicalRecordTabs';
import { getPatient } from 'reducers/patientReducer';
import { selectPatientData } from 'reducers/patientReducer';

// main component
const Filter = ({ state, setState }) => {

  const [patients, setPatients] = useState([]);
  useEffect(() => {
    axios
      .get('/patient', {
        params: {
          patient_status: [
            Status.NEW,
            Status.ADMITTED,
            Status.OUTPATIENT,
            Status.EMERGENCY,
            Status.FOR_DISCHARGE,
            Status.DECLINED,
            Status.AWAITING_BED,
          ],
        },
      })
      .then((response) => {
        setPatients(response.data.data);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });
  }, []);

  // Search Patient by name
  const [word, setWord] = useState('');
  const onChangeHandler = (event) => {
    const searchedWord = event.target.value;
    setWord(event.target.value);
    const newFilter = state.filter((value) => {
      return value.full_name.toLowerCase().includes(searchedWord.toLowerCase());
    });

    if (searchedWord.length > 0) {
      setState(newFilter);
    } else {
      setState(patients);
    }
  };

  // search by patient MRN
  const [MRN, setMRN] = useState('');
  const onChangeHandlerMRN = (event) => {

    const searchedWord = event.target.value;
    setMRN(event.target.value);
    const newFilter = patients.filter((value) => {

      const match = String(value.MRN) === String(searchedWord);

      if (match) {
        return match;
      } else {
        return String(value.id) === String(searchedWord);
      }

    });

    if (searchedWord.length > 0) {
      setState(newFilter);
    } else {
      setState(patients);
    }

  };

  const resetFilter = () => {
    setState(patients);
    setWord('');
    setMRN('');
  };

  return (
    <div className='Filter'>
      <FormUI>
        <Row className='mb-4'>
          <Col sm={3}>
            <Form.Group>
              <Form.Label>MRN or Patient ID</Form.Label>
              <FormControl
                type='text'
                className='bg-white'
                placeholder='Search Here'
                value={MRN}
                onChange={onChangeHandlerMRN}
              />
            </Form.Group>
          </Col>
          <Col sm={5}>
            <Form.Group>
              <Form.Label>Patient Name</Form.Label>
              <FormControl
                type='text'
                className='bg-white'
                placeholder='Search Here'
                value={word}
                onChange={onChangeHandler}
              />
            </Form.Group>
          </Col>
          <Col>
            <div className='buttonWrapper'>
              {/* <Form.Check type={'checkbox'} id={`admitted`} label={`Currently Admitted`} />
                      <Form.Check type={'checkbox'} id={`er-patient`} label={`Currently an ER Patient`} /> */}

              <Button variant='search' onClick={() => resetFilter()}>RESET</Button>
            </div>
          </Col>
        </Row>
      </FormUI>
    </div>
  );
};

Filter.defaultProps = {
  setState: () => { },
};

Filter.propTypes = {
  setState: PropTypes.func,
};

export default Filter;
